<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Processamentos SAFX / Mês</b-card-title>
      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="flatPickrConfig"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM"
          @input="onDateChange"
          />
      </div>
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <!-- <pre>{{ graficoData1 }}</pre> Exibe os dados para depuração -->
        <chartjs-component-bar-chart
          :key="chartKey"  
          v-if="graficoData1.labels.length && graficoData1.datasets[0].data.length"
          :data="graficoData1"
          :height="400"
          :width="800"
          :options="chartjsData.latestBarChart.options"
        />
    </b-card-body>
  </b-card>
</template>

<script>
import { Portuguese } from "flatpickr/dist/l10n/pt.js"
import 'flatpickr/dist/flatpickr.css'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import axios from 'axios'
import {
  BCard, BCardHeader, BCardBody, BCardTitle,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentBarChart from '../charts-components/ChartjsComponentBarChart.vue'
import chartjsData from '../chartjsData'

export default {
  props: {
    graficoData1: {
      type: Object,
      required: true,
    },
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    flatPickr,
    ChartjsComponentBarChart,
  },
  data() {
    return {
      rangePicker: null, // Data selecionada
      flatPickrConfig: {
        mode: 'single', // Seleção única
        dateFormat: 'Y-m', // Formato do valor retornado
        altInput: true, // Mostra um input formatado
        altFormat: 'F Y', // Formato exibido no input (ex.: Outubro 2024)
        locale: Portuguese, // Define o idioma para o datepicker
        plugins: [
          new monthSelectPlugin({
            shorthand: true, // Mostra abreviações dos meses (opcional)
            dateFormat: 'Y-m', // Formato real dos dados
            altFormat: 'F Y', // Formato visível para o usuário
          }),
        ],
      },
      chartKey: 0, // Inicializa uma key para o gráfico
      chartjsData,
      graficoData1: {
      labels: [],
      datasets: []
       },
      rangePicker: null,
    }
  },
  watch: {
    graficoData1: {
      handler(newData) {
        // Atualiza a key sempre que graficoData1 mudar
        this.chartKey += 1
      },
      deep: true,
    },
  },

  methods: {
  onDateChange(selectedDate) {
    this.$emit('date-changed', selectedDate);
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.flatpickr-monthSelect-months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centraliza os meses */
  padding: 10px;
}

.flatpickr-monthSelect-month {
  width: 70px; /* Ajuste o tamanho dos meses */
  height: 30px; /* Altura dos meses */
  line-height: 30px; /* Alinha o texto no centro */
  margin: 5px; /* Espaço entre os meses */
  text-align: center;
  cursor: pointer;
  border: 1px solid transparent; /* Borda inicial */
  border-radius: 4px; /* Bordas arredondadas */
}

.flatpickr-monthSelect-month:hover {
  background-color: #f0f0f0; /* Efeito hover */
  border: 1px solid #ddd;
}

.flatpickr-monthSelect-month.selected {
  background-color: #007bff; /* Cor de fundo do mês selecionado */
  color: #fff;
  border: 1px solid #007bff;
}
</style>
