<template>
  <div>
    <div class="mb-3">
      <b-row>
        <b-col cols="4">

          <b-form-group
            description="Selecione o CNPJ."
          >

            <combo-box-client ref="cboxclient" />

          </b-form-group>

        </b-col>

        <b-col cols="4">
          <b-form-group>
            <b-button
              variant="primary"
              type="button"
              @click="filesTable()"
            >
              Carregar
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <!-- Table  -->
    <div class="mb-2">
      <b-table
        id=""
        :items="filesItems"
        :fields="filesFields"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template #cell(actions)="row">

          <!-- Delete Button -->
          <b-button
            size="sm"
            @click.prevent="deleteFileUploaded(row.item.file, $event.target)"
          >
            DELETE
          </b-button>

        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </div>

    <!-- Upload files component -->
    <div>
      <file-upload />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ComboBoxClient from '@/views/reusable-components/ComboBoxClient.vue'
import FileUpload from '@/views/fileupload/FileUploadComp.vue'
import {
  BRow, BCol, BButton, BTable, BPagination, BFormGroup,
} from 'bootstrap-vue'

export default {
  components: {
    FileUpload,
    BFormGroup,
    BPagination,
    BTable,
    ComboBoxClient,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      perPage: 20,
      totalRows: 1,
      currentPage: 1,
      selectedCnpj: null,
      filesFields: [
        {
          key: 'file', label: 'Arquivos',
        },
        { key: 'actions', label: 'Actions' },
      ],
      filesItems: [],
    }
  },

  methods: {
    toast(toaster, append = false, variant, message, title) {
      this.$bvToast.toast(message, {
        title,
        toaster,
        solid: true,
        appendToast: append,
        variant,
      })
    },
    // trazendo arquivos
    async filesTable() {
      try {
        this.selectedCnpj = this.$refs.cboxclient.selected
        if (this.selectedCnpj === null) {
          this.toast('b-toaster-top-right', true, 'danger', 'Selecione um cliente!', 'Erro de usabilidade')
          throw new Error('Selecione um cliente!')
        }

        const res = await axios.get(`/fileupload/uploadfiles/${this.selectedCnpj}`)
        this.filesItems = res.data
        this.totalRows = this.filesItems.length
        this.currentPage = 1
        console.log(res.data)
      } catch (error) {
        console.log(error)
      }
    },
    async deleteFileUploaded(filename) {
      try {
        await axios.delete(`/fileupload/deletefile/${this.selectedCnpj}/${filename}`)
        this.toast('b-toaster-top-right', true, 'success', 'Arquivo excluído com sucesso!', 'Sucesso')
        this.filesTable()
      } catch (error) {
        console.log(error)
      }
    },

  },
}
</script>

<style>

</style>
