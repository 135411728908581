<template>
  <div>
    <b-modal
      :id="novaEmpresa.id"
      ref="novaEmpresaMdl"
      :title="novaEmpresa.title"
      :content="novaEmpresa.content"
      hide-footer
    >
      <div>
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>

              <b-col md="12">
                <b-form-group>
                  <b-badge variant="primary">Dados da Empresa
                  </b-badge>
                  <br>
                  <br>
                  <!-- CNPJ -->

                  <label>CNPJ Empresa</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required|digits:14"
                    name="CNPJ"
                  >
                    <b-form-input
                      v-model="itemCnpj"
                      :state="errors.length > 0 ? false:null"
                      placeholder="CNPJ"
                      :readonly="readonly"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Codigo-->
              <b-col md="12">
                <b-form-group>
                  <label>Codigo</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Cod"
                  >
                    <b-form-input
                      v-model="itemCod"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Codigo"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!--  Descrição -->
              <b-col md="12">
                <b-form-group>
                  <label>Descrição</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Descr"
                  >
                    <b-form-input
                      v-model="itemDescr"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Descrição"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Para -->
              <b-col md="12">
                <b-form-group>
                  <label>Para</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Para"
                  >
                    <b-form-input
                      v-model="itemPara"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Para"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Buttons -->
              <b-col class="demo-inline-spacing">

                <!-- Adicionar Nova Empresa button -->
                <b-button
                  :id="registerButton.id"
                  :content="registerButton.content"
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm()"
                >
                  {{ registerButton.content }}
                </b-button>

              </b-col>
            </b-row>
          </b-form>
        </validation-observer>

      </div>
    </b-modal>
    <b-modal
      id="modal-edit-empresa"
      ref="modal-edit-empresa"
      title="Editar Empresa"
      hide-footer
    >
      <!-- ... -->

      <!-- Editar Empresa button -->
      <b-button
        id="editarEmpresa"
        variant="primary"
        type="submit"
        @click.prevent="validationForm('edit')"
      >
        Editar Empresa
      </b-button>

      <!-- ... -->
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import {
  BButton, BModal, BFormGroup, BForm, BRow, BCol, BFormInput, BBadge,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'ModalNewCompany',
  components: {
    BBadge,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BForm,
    BRow,
    BCol,
    BButton,
    BModal,
  },
  data() {
    return {
      readonly: false,
      novaEmpresa: {
        id: 'modal-prevent-closing',
        title: 'Editar Empresa',
        content: '',
      },
      registerButton: {
        id: 'register-button',
        content: 'Adicionar Empresa',
      },
      nomeEmpresa: '',
      required,
      itemCnpj: null,
      companyCnpj: null,
      itemCod: null,
      itemDescr: null,

    }
  },
  methods: {
    toast(toaster, append = false, variant, message, title) {
      this.$bvToast.toast(message, {
        title,
        toaster,
        solid: true,
        appendToast: append,
        variant,
      })
    },
    validationForm() {
      this.readonly = false
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.novaEmpresa.title === 'Adicionar Nova Empresa') {
            this.addCompany()
          } else {
            this.updateCompany()
          }
        }
      })
    },
    async addCompany() {
      let selectedCnpj = ''
      try {
        selectedCnpj = this.$parent.$refs.cboxclient.selected

        if (selectedCnpj === null) {
          throw Error('Selecione um cliente!')
        }
        // Getting values for POST
        const data = {
          id_cnpj: this.itemCnpj,
          cod: this.itemCod,
          to_rule: this.itemPara,
          name: this.itemDescr,
        }
        // End getting values for post

        await axios.post(`/company/${selectedCnpj}`, data)
          .then(response => {
            this.toast('b-toaster-top-right', true, 'success', `Empresa ${this.itemDescr} cadastrada com sucesso`, 'Cadastro de Empresa')
            console.log(response)
          })// const res = resposta que vem do axios.

        // deixa o campos em branco após salvo.
        this.itemCnpj = ''
        this.itemCod = ''
        this.itemPara = ''
        this.itemDescr = ''

        this.$refs.novaEmpresaMdl.hide()

        // Atualizar table
        this.$parent.companyTable()
      } catch (error) {
        if (selectedCnpj === null) {
          this.toast('b-toaster-top-right', true, 'danger', 'Selecione um cliente!', 'Erro de usabilidade')
        } else if (error.response) {
          if (error.response.status === 500) {
            this.toast('b-toaster-top-right', true, 'danger', ` ${error}`)
          } else {
            this.toast('b-toaster-top-right', true, 'warning', `${error}`, 'Cadastro de Empresa')
          }
        } else {
          console.log(error)
        }
      }
    },
    async updateCompany() {
      const selectedCnpj = this.$parent.$refs.cboxclient.selected
      try {
        const res = await axios.patch(`/company/update/${selectedCnpj}`, {
          id_cnpj: this.itemCnpj,
          cod: this.itemCod,
          to_rule: this.itemPara,
          name: this.itemDescr,

        })

        this.toast('b-toaster-top-right', true, 'success', `CNPJ ${res.data.id_cnpj} Atualizado com sucesso`, 'Cadastro Empresa')
        // "this.toast"onde chama o metodo que esta acima qndo se clica no botão.
        // foi colocado aqui pois não é correto adicionar 2 eventos no onclick, então chamamos um método dentro do outro, toast
        // dentro do addClick
        this.$refs.novaEmpresaMdl.hide()

        // Atualizar table
        this.$parent.companyTable()
      } catch (error) {
        if (error.response.status === 500) {
          this.toast('b-toaster-top-right', true, 'danger', ` ${error}`)
        } else {
          this.toast('b-toaster-top-right', true, 'warning', `CNPJ ${this.itemCnpj} já existe`, 'Cadastro Empresa')
        }
      }
    },
  },
}
</script>
