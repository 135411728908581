<template>
  <div />
</template>

<script>
/* eslint-disable global-require */
// eslint-disable-next-line camelcase
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  name: 'ValidateLogonKeycloak',
  components: { },
  data() {
    return {
      logon: {
        id: 'test',
      },
    }
  },
  methods: {
    showSome() {
      console.log('Test')
    },
    async validateLogon() {
      if (localStorage.getItem('accessToken') === null || localStorage.getItem('accessToken') === 'undefined') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Efetuar logon',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
        this.$router.push('/')
        throw new Error('No token, logon in the system')
      }

      const userData = localStorage.getItem('userData')
      const refreshToken = localStorage.getItem('refreshToken')

      await axios
        .post('/auth/authenticate', { refresh_token: refreshToken })
        .then(response => {
          const extractedToken = response.data
          localStorage.setItem('accessToken', extractedToken.access_token)
          localStorage.setItem('refreshToken', extractedToken.refresh_token)
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Session expired',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          console.log(err)
          this.$router.push('/')
          throw new Error('Token expired')
        })

      return userData
    },
  },
}
</script>
