<template>
  <div>
    <!-- MODAL para adicionar novo de/para -->
    <b-modal
      :id="novodePara.id"
      title="Novo De/Para"
      hide-footer
      no-close-on-backdrop
    >
      <b-form-group
        label="Id"
      >
        <b-form-input
          id=""
          ref="id_conversion"
          v-model="id_conversion"
          :readonly="readonly"
        />
      </b-form-group>
      <div>
        <validation-observer ref="newRules">
          <b-form>
            <b-row>
              <b-col md="12">
                <!-- Select Coluna -->
                <b-form-group
                  label="Coluna"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Coluna"
                  >
                    <v-select
                      v-model="selected_safxcol"
                      :options="options_safxcol"
                      placeholder="Coluna"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="De"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="De"
                  >
                    <b-form-input
                      id=""
                      v-model="de"
                      placeholder="De"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Para"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Para"
                  >
                    <b-form-input
                      id=""
                      v-model="para"
                      placeholder="Para"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="demo-inline-spacing">
                <b-button
                  id=""
                  variant="primary"
                  type="button"
                  @click="$bvModal.hide('novodePara')"
                >
                  Cancelar
                </b-button>

                <!-- Adicionar Nova Regra button -->
                <b-button
                  id="novaRegra"
                  variant="primary"
                  type="button"
                  @click="validationForm"
                >
                  Adicionar Novo De/para
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select'
import {
  BButton, BModal, BFormInput, BFormGroup, BForm, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'ModalDeparaNew',
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BModal,
  },
  data() {
    return {
      novodePara: {
        id: 'novodePara',
        title: '',
        content: '',
      },
      selected_safx: null,
      selected_safxcol: null,
      de: null,
      para: null,
      id_conversion: null,
      readonly: true,
      options_safx: [
        { value: null, label: 'Selecione a tabela' },
      ],
      options_safxcol: [
        { value: null, label: 'Selecione a coluna' },
      ],
      required,
    }
  },
  methods: {
    toast(toaster, append = false, variant, message, title) {
      this.$bvToast.toast(message, {
        title,
        toaster,
        solid: true,
        appendToast: append,
        variant,
      })
    },
    validationForm() {
      this.$refs.newRules.validate().then(success => {
        if (success) {
          this.postDePara()
        }
      })
    },
    async postDePara() {
      try {
        // Getting values for POST
        const idSafxCol = this.selected_safxcol.value
        const from = this.de
        const to = this.para
        const idConversion = this.id_conversion
        // End getting values for post

        const resdePara = await axios.post(`/custom_conversion_rule/${idConversion}/${idSafxCol}`,
          {
            from_rule: from,
            to_rule: to,
          })

        // this.$parent.$refs.modaldepara.$refs.depara_001.items = [...this.$parent.$refs.modaldepara.$refs.depara_001.items, resdePara.data]
        this.$parent.$refs.modaldepara.getDePara(idConversion)
        // this.totalRows = this.btable_rule_items.length
        // this.currentPage = 1
        this.toast('b-toaster-top-right', true, 'success', 'De/Para cadastrado com sucesso', 'Cadastro de regra')
        console.log(resdePara.data)
        // deixar o campo em branco após ter adicionado
        this.de = ''
        this.para = ''
        // hide (fechar o modal após o item ser postado)
        this.$bvModal.hide('novodePara')
      } catch (error) {
        console.log(error)
      }
    },
    async getSafxCol(idSafx) {
      try {
        const responseFiltered = []
        const resSafxCol = await axios.get(`/safx_col/${idSafx}`)

        resSafxCol.data.forEach(item => {
          responseFiltered.push({
            value: item.id_safxcol,
            label: `${item.col_name}`,
          })
        })

        this.options_safxcol = responseFiltered
        console.log(responseFiltered)
        // this.totalRows = this.btable_rule_items.length
        // this.currentPage = 1
      } catch (error) {
        console.log(error)
      }
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
