import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/home/Home.vue'),
      meta: {
        action: 'read',
        resource: 'Auth',
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/empresa',
      name: 'empresa',
      component: () => import('@/views/empresaestab/EmpresaEstab.vue'),
      meta: {
        action: 'read',
        resource: 'Auth',
        pageTitle: 'Empresa e Estabelecimento',
        breadcrumb: [
          {
            text: 'Empresa e Estabelecimento',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cliente',
      name: 'cliente',
      component: () => import('@/views/cliente/Cliente.vue'),
      meta: {
        pageTitle: 'Cliente',
        breadcrumb: [
          {
            text: 'Cliente',
            active: true,
          },
        ],
        resource: 'admin',
        action: 'read',
      },
    },
    {
      path: '/processamento',
      name: 'processamento',
      component: () => import('@/views/processamento/Processamento.vue'),
      meta: {
        action: 'read',
        resource: 'Auth',
        pageTitle: 'Processamento',
        breadcrumb: [
          {
            text: 'Processamento',
            active: true,
          },
        ],
      },
    },

    {
      path: '/paramfisc',
      name: 'paramfisc',
      component: () => import('@/views/paramFisc/ParametrosFisc.vue'),
      meta: {
        action: 'read',
        resource: 'Auth',
        pageTitle: 'Parametros Fiscais',
        breadcrumb: [
          {
            text: 'Parametros Fiscais',
            active: true,
          },
        ],
      },
    },
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/Users/Users.vue'),
      meta: {
        pageTitle: 'Usuários',
        breadcrumb: [
          {
            text: 'Usuários',
            active: true,
          },
        ],
      },
    },
    {
      path: '/fileupload',
      name: 'fileupload',
      component: () => import('@/views/fileupload/UploadFilesTable.vue'),
      meta: {
        action: 'read',
        resource: 'Auth',
        pageTitle: 'Upload de arquivo',
        breadcrumb: [
          {
            text: 'Upload de arquivo',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
