<template>
  <div>
    <b-modal
      id="nova-regra"
      ref="novaRegraMdl"
      title="Nova Regra De/Para"
      hide-footer
      no-close-on-backdrop
    >
      <div>
        <validation-observer ref="newRules">
          <b-form>
            <b-row>
              <b-col cols="12">

                <b-form-group>

                  <label>Interface (SAFX)</label>

                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="SAFX"
                  >
                    <v-select
                      v-model="selected_safx"
                      placeholder="SAFX"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="options_safx"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="demo-inline-spacing">
                <b-button
                  id=""
                  variant="primary"
                  type="button"
                  @click="$bvModal.hide('nova-regra')"
                >
                  Cancelar
                </b-button>

                <!-- Adicionar Nova Regra button -->
                <b-button
                  id="novaRegra"
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
                >
                  Adicionar Nova Regra
                </b-button>

              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select'
import {
  BButton, BModal, BFormGroup, BForm, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'ModalNewRule',
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BModal,
  },
  data() {
    return {
      nomeRegra: '',
      required,
      selected_safx: null,
      options_safx: [
        { value: null, label: 'Selecione SAFX' },
      ],

    }
  },
  created() {
    this.getSAFX()
  },
  methods: {
    toast(toaster, append = false, variant, message, title) {
      this.$bvToast.toast(message, {
        title,
        toaster,
        solid: true,
        appendToast: append,
        variant,
      })
    },
    validationForm() {
      this.$refs.newRules.validate().then(success => {
        if (success) {
          this.addRule()
        }
      })
    },
    async getSAFX() {
      try {
        const responseFiltered = []
        const resSafx = await axios.get('/safx/')

        resSafx.data.forEach(item => {
          responseFiltered.push({
            value: item.id_safx,
            label: `${item.table_name} - ${item.desc}`,
          })
        })

        this.options_safx = responseFiltered
      } catch (error) {
        console.log(error)
      }
    },
    async addRule() {
      let selectedCnpj = ''
      try {
        selectedCnpj = this.$parent.$refs.cboxclient.selected
        if (selectedCnpj === null) {
          throw Error('Selecione um cliente!')
        }
        const idSafx = this.selected_safx.value
        await axios.post(`/custom_conversion/${selectedCnpj}/${idSafx}`, {
          nme_conversion: 'Dummy',
        })// const res = resposta que vem do axios.
        this.toast('b-toaster-top-right', true, 'success', `Regra ${this.nomeRegra} cadastrada com sucesso`, 'Cadastro de regra')

        // deixa o campos em branco após salvo.
        this.nme_conversion = ''

        // "this.toast"onde chama o metodo que esta acima qndo se clica no botão.
        // foi colocado aqui pois não é correto adicionar 2 eventos no onclick, então chamamos um método dentro do outro, toast
        // dentro do addClick
        this.$refs.novaRegraMdl.hide()

        // Atualizar table
        this.$parent.rulesTable()
      } catch (error) {
        if (selectedCnpj === null) {
          this.toast('b-toaster-top-right', true, 'danger', 'Selecione um cliente!', 'Erro de usabilidade')
        } else if (error.response) {
          if (error.response.status === 500) {
            this.toast('b-toaster-top-right', true, 'danger', ` ${error}`)
          } else {
            this.toast('b-toaster-top-right', true, 'warning', `Regra ${this.nomeRegra} ja existe`, 'Cadastro de regra')
          }
        } else {
          console.log(error)
        }
      }
    },

  },
}
</script>
