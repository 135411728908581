<template>
  <div>
    <!-- datatable -->
    <b-row>
      <b-col
        md="2"
        sm="4"
        class="my-1"
      >
        <b-form-group
          class="mb-0"
        >
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="4"
        sm="8"
        class="my-1"
      >
        <b-form-group
          label="Sort"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">
                  -- none --
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
              class="w-25"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="my-1"
      >
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-overlay
          :show="show"
          spinner-variant="primary"
          spinner-type="border"
          spinner-large
          rounded="lg"
        >
          <b-table
            :id="bTable.id"
            ref="btable_cliente_001"
            striped
            hover
            responsive
            :busy.sync="isBusy"
            :per-page="perPage"
            :current-page="currentPage"
            :items="btable_items_001"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <!-- coluna actions botões Edit e Delete-->
            <template #cell(actions)="row">
              <!-- Edit Button -->
              <b-button-toolbar>
                <b-button-group size="sm">
                  <b-button
                    size="sm"
                    @click="editModal(row.item, $event.target)"
                  >
                    EDIT
                  </b-button>
                  <!-- Delete Button -->
                  <b-button
                    size="sm"
                    @click.prevent="modalBeforeDel(row.item, $event.target)"
                  >
                    DELETE
                  </b-button>
                </b-button-group>
              </b-button-toolbar>
            </template>
          </b-table>
        </b-overlay>
        <!-- Delete Modal  -->
        <div>
          <b-modal
            :id="modalDelete.id"
            ref="modal-delete"
            :title="modalDelete.title"
            :content="modalDelete.content"
            button-size="sm"
            hide-footer
          >
            <div>
              <!-- Como renderizar o cnpj específico que foi clicado? -->
              Tem certeza que deseja excluir este CNPJ?
            </div>

            <!-- Botão cancelar dentro do botão delete -->
            <b-col class="demo-inline-spacing">
              <b-button
                id=""
                variant="primary"
                type="button"
                @click="hideModal1()"
              >
                Cancelar
              </b-button>

              <!-- Excluir button -->
              <b-button
                id="deleteButton"
                variant="primary"
                type="button"
                @click.prevent="deleteClient()"
              >
                Excluir
              </b-button>
            </b-col>
          </b-modal>
        </div>
      </b-col>

      <b-col
        cols="12"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
    <!-- MODAL BUTTON, button that opens ModalEmpresa component-->

    <b-button
      :id="btnAdd.id"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      :disabled="btnAdd.disabled"
      variant="outline-primary"
      @click="addModal($event.target)"
    >
      Cadastrar Cliente
    </b-button>

    <modal-empresa ref="mempresa" />
    <validate-logon-keycloak ref="validatelogon" />
  </div>
</template>

<script>
import axios from 'axios'
import {
  BButtonToolbar,
  BButtonGroup,
  BOverlay,
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ModalEmpresa from './ModalEmpresa.vue'
import ValidateLogonKeycloak from '../reusable-components/ValidateLogonKeycloak.vue'

export default {
  name: 'Cliente',
  components: {
    BButtonToolbar,
    BButtonGroup,
    BOverlay,
    ModalEmpresa,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    ValidateLogonKeycloak,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      butCadCliente: {
        id: 'cadCliente',
        disabled: false,
      },
      rolesNeeded: ['full'],
      // readonly: true,
      show: false,
      perPage: 10,
      pageOptions: [5, 10, 20, 30],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      modalDelete: {
        id: 'modalDelete',
        title: '',
        content: 'Excluir',
      },
      btnAdd: {
        id: 'btnAdd',
        disabled: false,
      },
      bTable: {
        id: 'btable_001',
      },
      fields: [
        {
          key: 'id_cnpj', label: 'CNPJ', sortable: true,
        },
        // {
        //   key: 'cod', label: 'COD', sortable: true,
        // },
        {
          key: 'name', label: 'NOME', sortable: true,
        },
        // {
        //   key: 'email', label: 'Email', sortable: true,
        // },
        // {
        //   key: 'telephone', label: 'Telefone', sortable: true,
        // },
        {
          key: 'tr_user', label: 'Tr_user', sortable: true,
        },
        { key: 'actions', label: 'Actions' },
      ],
      btable_items_001: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() { // Mounted has to be used when using a Child and trigger after the import
    this.validateUserLogon()
    this.setUser()
  },
  created() {
    if (this.validatePermissions()) {
      this.createTable()
    } else {
      this.btnAdd.disabled = true
      this.btnAdd.disabled = true
    }
  },
  methods: {
    validateUserLogon() {
      this.$refs.validatelogon.validateLogon()
    },
    setUser() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      this.$parent.$parent.$parent.$refs.adenav.username = userData.fullName
    },
    modalBeforeDel(item, button) {
      this.modalDelete.title = `${item.id_cnpj}`

      this.$root.$emit('bv::show::modal', this.modalDelete.id, button)// Faz o modal aparecer
    },
    hideModal1() {
      this.$refs['modal-delete'].hide()
    },
    toast(toaster, append = false, variant, message, title) {
      this.$bvToast.toast(message, {
        title,
        toaster,
        solid: true,
        appendToast: append,
        variant,
      })
    },
    validatePermissions() {
      let allowedUser = false
      const userData = JSON.parse(localStorage.getItem('userData'))
      const allowedGroup = userData.group

      if (allowedGroup === 'admin') {
        allowedUser = true
      }

      return allowedUser
    },
    async createTable() {
      this.show = true // loading page b-overlay
      this.isBusy = true
      try {
        const res = await axios.get('/companyuser/?skip=0&limit=100')
        // const res = await axios.get('http://localhost:3000/cliente')
        this.btable_items_001 = res.data
        this.totalRows = this.btable_items_001.length
        this.currentPage = 1
      } catch (error) {
        console.log(error)
      }
      this.isBusy = false
      this.show = false
    },
    async deleteClient() {
      try {
        await axios.delete(`/companyuser/${this.modalDelete.title}`)
        this.toast('b-toaster-top-right', true, 'success', 'Cliente foi deletado com sucesso!', 'Cliente Excluído')
        // "this.toast"onde chama o metodo que esta acima qndo se clica no botão.
        // foi colocado aqui pois não é correto adicionar 2 eventos no onclick, então chamamos um método dentro do outro, toast
        // dentro do addClick
        this.createTable()
        this.hideModal1()
      } catch (error) {
        console.log(error)
        this.toast('b-toaster-top-right', true, 'danger', ` ${error}`)
        if (error.response.status === 500) {
          this.toast('b-toaster-top-right', true, 'danger', ` ${error}`)
        } else {
          this.toast('b-toaster-top-right', true, 'warning', `CNPJ ${this.modalDelete.title} não encontrado`, 'Deletar Cliente')
        }
      }
    },
    // addModal é a função que está no botão cadastrar cliente linha 194 e tem como objetivo fazer o
    // cont. modal voltar ao normal depois que aperta o edit
    addModal(button) {
      // mempresa refere-se ao nome dado a ref dentro da tag que renderiza o ModalEmpresa
      // clientModal é o id no b-modal dentro do component ModalEmpresa
      this.$refs.mempresa.readonly = false
      this.$refs.mempresa.clientModal.title = 'Cadastro de Cliente'
      this.$refs.mempresa.registerButton.content = 'Cadastrar Cliente'
      this.$refs.mempresa.itemId_cnpj = ''
      this.$refs.mempresa.itemCod = ''
      this.$refs.mempresa.itemName = ''
      this.$refs.mempresa.itemEmail = ''
      this.$refs.mempresa.itemTelephone = ''
      this.$refs.mempresa.itemTr_user = ''
      this.$refs.mempresa.itemTr_passwd = ''
      this.$root.$emit('bv::show::modal', this.$refs.mempresa.clientModal.id, button)// Faz o modal aparecer
    },
    editModal(item, button) {
      // mempresa refere-se ao nome dado a ref dentro da tag que renderiza o ModalEmpresa
      // clientModal é o id no b-modal dentro do component ModalEmpresa
      this.$refs.mempresa.readonly = true
      this.$refs.mempresa.clientModal.title = `Atualizar empresa ${item.id_cnpj}`
      this.$refs.mempresa.registerButton.content = 'Atualizar'
      this.$refs.mempresa.itemId_cnpj = item.id_cnpj
      this.$refs.mempresa.itemCod = item.cod
      this.$refs.mempresa.itemName = item.name
      this.$refs.mempresa.itemEmail = item.email
      this.$refs.mempresa.itemTelephone = item.telephone
      this.$refs.mempresa.itemTr_user = item.tr_user
      this.$refs.mempresa.itemTr_passwd = item.tr_passwd

      this.$root.$emit('bv::show::modal', this.$refs.mempresa.clientModal.id, button)// Faz o modal aparecer
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
