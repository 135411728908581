import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
// import '@/libs/acl'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import axios from 'axios'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.devtools = false


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

// Adicionando o interceptor de requisição
axios.interceptors.request.use(
  config => {
      // Pegando o token de acesso do localStorage
      const refresh_token = localStorage.getItem('refreshToken')
      if (refresh_token) {
          // Setando o refresh_token no cabeçalho de autorização
          config.headers.Authorization = refresh_token
      }
      return config
  },
  error => {
      return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  res => {
      
      //console.log('Response completo:', res)
      // Pegando o token de acesso do localStorage e gravando com setItem
      if (res.headers.authorization != "undefined"){
        localStorage.setItem('refreshToken', res.headers.authorization)
        //console.log('Token salvo no localStorage:', res.headers.authorization)
      }

        return res
  },
  error => {
      return Promise.reject(error)
  }
)

