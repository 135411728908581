var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modalAgendamento",attrs:{"id":_vm.agendamento.id,"title":_vm.agendamento.title,"hide-footer":"","no-close-on-backdrop":""}},[_c('b-overlay',{attrs:{"show":_vm.show,"spinner-variant":"primary","spinner-type":"border","spinner-large":"","rounded":"lg"}},[_c('div',[_c('validation-observer',{ref:"newRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":""}},[_c('br'),_c('h4',[_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(" Agendamento ")])],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"SAFX"}},[_c('validation-provider',{attrs:{"rules":"required","name":"SAFX"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"Selecione SAFX","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.options_safx,"state":errors.length > 0 ? false:null},model:{value:(_vm.selected_safx),callback:function ($$v) {_vm.selected_safx=$$v},expression:"selected_safx"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Frequência"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Frequência"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"Selecione Frequência","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.options_freq,"state":errors.length > 0 ? false:null},on:{"input":_vm.handleFrequencyChange},model:{value:(_vm.selected_freq),callback:function ($$v) {_vm.selected_freq=$$v},expression:"selected_freq"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.showDayOfMonth)?_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Dia do Mês"}},[_c('v-select',{attrs:{"placeholder":"Selecione o Dia do Mês","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.options_days},model:{value:(_vm.selected_day),callback:function ($$v) {_vm.selected_day=$$v},expression:"selected_day"}})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Horário"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Horário"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"Selecione Horário","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.options_time,"state":errors.length > 0 ? false:null},model:{value:(_vm.selected_time),callback:function ($$v) {_vm.selected_time=$$v},expression:"selected_time"}}),_c('b-input-group',{}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('br'),_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v("Integração via arquivo ")]),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{attrs:{"id":"checkbox-group-2","aria-describedby":ariaDescribedby,"name":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('b-form-checkbox',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topright",value:('Caso a integração via arquivos não utilize filtros de empresa e estabelecimento'),expression:"'Caso a integração via arquivos não utilize filtros de empresa e estabelecimento'",modifiers:{"hover":true,"topright":true}}],attrs:{"value":"use_filter"}},[_vm._v(" Não utilizar filtros ")]),_c('br'),_c('br'),_c('b-form-checkbox',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topright",value:('Deleta todos os arquivos disponíveis no diretório de upload'),expression:"'Deleta todos os arquivos disponíveis no diretório de upload'",modifiers:{"hover":true,"topright":true}}],attrs:{"value":"delete_files"}},[_vm._v(" Deletar arquivos carregados ")])],1)]}}])})],1)],1),_c('b-col',{staticClass:"demo-inline-spacing"},[_c('b-button',{attrs:{"id":"","variant":"primary","type":"button"},on:{"click":function($event){return _vm.hidemodalAgendamento()}}},[_vm._v(" Cancelar ")]),_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.show,"rounded":"","opacity":"0.6","spinner-small":"","spinner-variant":"primary"}},[_c('b-button',{attrs:{"id":"executButton","variant":"primary","type":"button"},on:{"click":_vm.validationFormAgendar}},[_vm._v(" Agendar ")])],1)],1)],1)],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }