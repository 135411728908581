<template>
  <div>
    <!-- MODAL para adicionar novo de/para -->
    
    <b-modal
      :id="modalDag.id"
      ref="modalExecutar"
      :title="modalDag.title"
      size="lg"
      hide-footer
      no-close-on-backdrop
     @close="hideModalExecutar()"
    >
    <b-overlay
      :show="show"
      rounded
      opacity="0.6"
      spinner-small
      spinner-variant="primary"
      class="d-inline-block"
    >  
      

        <validation-observer ref="newRules">
          <b-form>
            <b-row>
              <b-col cols="12">
                <!-- Cod Empresa -->

                <b-form-group
                  label="Cod Empresa"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Cod Empresa"
                  >
                    <v-select
                      v-model="selected_empresa"
                      placeholder="Selecione Empresa"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="options_empresa"
                      :state="errors.length > 0 ? false:null"
                      @input="getEstab()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

                <!-- Cod Estabelecimento -->
              <b-col cols="12">   
                <b-form-group
                  label="Cod Estabelecimento"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Cod Estabelecimento"
                  >
                    
                      <div class="checkbox-group-container">

                        <b-form-checkbox-group
                          v-if="selected_empresa"
                          v-model="selected_estab"
                          :options="options_estab"
                          stacked
                        />

                      </div>
                      <div class="mt-2 form-check">
                        <b-form-checkbox
                          v-model="allSelected"
                          :indeterminate="indeterminate"
                          @change="toggleAll"
                        >
                          Select All
                        </b-form-checkbox>
                      </div>

                    
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                  <div
                    v-if="selected_empresa"
                    class="mt-2"
                  >
                    Selected: <strong>{{ selected_estab }}</strong>
                  </div>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                    label="Data Início"
                    class="mt-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Data Início"
                    >
                      <b-input-group class="">
                        <b-form-input
                          id="valueDataIni"
                          v-model="valueDataIni"
                          type="text"
                          placeholder="YYYY-MM-DD"
                          autocomplete="on"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                            id="valueDataIni"
                            v-model="valueDataIni"
                            button-only
                            right
                            locale="pt"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
              </b-col>
              
              <b-col cols="12">
                 <!-- Data Fim -->
                 <b-form-group
                    label="Data Fim"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Data Fim"
                    >
                      <b-input-group class="">
                        <b-form-input
                          id="valueDataFim"
                          v-model="valueDataFim"
                          type="text"
                          placeholder="YYYY-MM-DD"
                          autocomplete="off"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                            id="valueDataFim"
                            v-model="valueDataFim"
                            button-only
                            right
                            locale="pt"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                 
                
              <b-col class="demo-inline-spacing">
                <b-button
                  id=""
                  variant="primary"
                  type="button"
                  @click="hideModalExecutar()"
                >
                  Cancelar
                </b-button>

                <!-- Adicionar Nova Regra button -->
                
                  <b-button
                    id="executButton"
                    variant="primary"
                    type="button"
                    @click="validationFormExecutar"
                  >
                    Executar
                  </b-button>
                

              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        
      
    </b-overlay>
    </b-modal>
  
  </div>
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select'
import {
  BButton, BModal, BFormDatepicker, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BForm, BRow, BCol, BOverlay, BInputGroup, BInputGroupAppend, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'ModalExecutar',
  components: {
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormDatepicker,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BForm,
    BRow,
    BCol,
    BButton,
    BModal,
  },
  data() {
    return {
      selected: [],
      allSelected: false,
      indeterminate: false,
      modalDag: {
        id: 'modalDag',
        title: '',
        content: '',
      },
      show: false,
      selected_empresa: '',
      selected_estab: [],
      valueDataIni: '',
      valueDataFim: '',
      id_conversion: null,
      readonly: true,
      options_empresa: [
        { value: null, label: 'Selecione a tabela' },
      ],
      options_estab: [
        { value: null, label: 'Selecione a tabela' },
      ],
      required,
    }
  },
  // Select all checkbox (button)
  watch: {
    selected_estab(newValue) {
      // Handle changes in individual checkboxes
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.options_estab.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    },
  },

  created() {
    this.getEmpresaTab()
  },
  methods: {
    toggleAll(checked) {
      this.selected_estab = checked ? this.options_estab.map(option => option.value) : []
    },
    toast(toaster, append = false, variant, message, title) {
      this.$bvToast.toast(message, {
        title,
        toaster,
        solid: true,
        appendToast: append,
        variant,
      })
    },

    validationFormAgendar() {
      this.$refs.newRules.validate().then(success => {
        if (success) {
          this.postAgendamento()
          // hide (fechar o modal após o item ser postado)
        }
      })
    },

    validationFormExecutar() {
      this.$refs.newRules.validate().then(success => {
        if (success) {
          this.postExecucao()
        }
      })
    },
    
    postExecucao() {
      this.show = true // loading page b-overlay
        try {
         // Getting values for POST
        const cnpjSelected = this.$parent.$refs.cboxclient.selected
        console.log(`${cnpjSelected}cnpj`)
        const safxSelected = this.modalDag.title
        console.log(`${safxSelected}safxSelected`)

        const empresaSelected = this.selected_empresa.value.COD
        console.log(`${empresaSelected}empresaSelected`)

        const estabValues = this.selected_estab.map(String)
        console.log(`${estabValues}estabSelected`)

        const cdataIni = this.valueDataIni.replace(/-/g, '')
        console.log(`${cdataIni}cdataIni`)

        const cdataFim = this.valueDataFim.replace(/-/g, '')
        console.log(`${cdataFim}cdataFim`)

        const user = JSON.parse(localStorage.getItem('userData'))?.username || "username não disponível"
        console.log(user)

        // Corpo da requisição apenas com COD_ESTAB
        const requestBody = estabValues
        
        console.log("requestBody:", requestBody)
        // End getting values for post

          

        axios.post(`/airflow/interfaceRunV2/${cnpjSelected}/${safxSelected}/${empresaSelected}/${cdataIni}/${cdataFim}/${user}`, requestBody)
         
          .then(() => {
            
              this.toast('b-toaster-top-right', true, 'success', `Execução efetuada com sucesso`, 'Execução SAFX')
              // hide (fechar o modal após o item ser postado)
              this.show = false // loading page b-overlay
              this.$refs.modalExecutar.hide()
              this.$parent.safxTable1()
           })
          .catch(error => {
            console.error("Erro na resposta da API:", error.response ? error.response.data : error); // Mostra o erro de resposta para ver o que está causando o 422
          })
         // deixa o campos em branco após salvo
          this.selected_empresa = ''
          this.selected_estab = []
          this.valueDataIni = ''
          this.valueDataFim = ''
          

        } catch (error) {
          console.log(error)
          
        }
     
      },
    async getEstab() {
      this.show = true // loading page b-overlay
      try {
        // Reset selected_estab when a new company is selected
        this.selected_estab = []
        const responseFiltered = []
        const getEstabFromEmpr = this.selected_empresa.value.CNPJ
        console.log(getEstabFromEmpr)
        const resEstab = await axios.get(`/branch/${getEstabFromEmpr}`)

        resEstab.data.forEach(item => {
          responseFiltered.push({
            value: item.cod,
            label: `${item.cod} - ${item.name}`,
            text: `${item.cod} - ${item.name}`,
          })
        })
        this.options_estab = responseFiltered.sort((a, b) => {
          const codA = parseInt(a.value) // Converte o 'cod' para inteiro
          const codB = parseInt(b.value)
          return codA - codB // Ordenação crescente
       })
        // this.totalRows2 = this.row.item.runs.length
        // this.currentPage2 = 1


      } catch (error) {
        console.log(error)
      }
      this.show = false
    },
    hideModalExecutar() {
      this.selected_empresa = ''
      this.selected_estab = []
      this.valueDataIni = ''
      this.valueDataFim = ''
      this.$refs.modalExecutar.hide()
    },
    async getEmpresaTab() {
      try {
        const responseFiltered = []
        const getCnpj = this.$parent.$refs.cboxclient.selected
        const resEmpresa = await axios.get(`/company/${getCnpj}`)
        console.log(resEmpresa)
        resEmpresa.data.forEach(item => {
          responseFiltered.push({
            value: { CNPJ: item.id_cnpj, COD: item.cod },
            label: `${item.id_cnpj} - ${item.cod} -${item.name}`,
          })
        })
        this.showEstab = true
        this.options_empresa = responseFiltered
      } catch (error) {
        console.log(error)
      }
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.checkbox-group-container {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 300px;

}
</style>
