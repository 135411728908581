<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div>
    <validate-logon-keycloak ref="validatelogon" />
    <div>
      <b-row>
        <b-col cols="4">
          <b-form-group
            description="Selecione o CNPJ."
          >
            <combo-box-client ref="cboxclient" />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group>
            <b-button
              variant="primary"
              :disabled="btnAllowed.disabled"
              type="button"
              @click="companyTable()"
            >
              Carregar
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <div>

      <!-- User Interface controls -->
      <div>
        <b-row>

          <b-row>
            <b-col
              md="12"
              class="mt-0 mb-2"
            >
              <b-form-group
                label="Per page"
                label-for="per-page-select"
                label-cols-sm="12"
                label-cols-md="8"
                label-cols-lg="5"
                label-align-sm="left"
                label-size="sm"
                class="mb-0 ml-1"
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-row>
      </div>
      <!-- End User Interface controls -->
      <!-- Main table element -->
      <b-row>
        <b-col cols="">
          <b-overlay
            :show="show"
            spinner-variant="primary"
            spinner-type="border"
            spinner-large
            rounded="lg"
          >
            <b-table
              :items="itemsEmpresa"
              :fields="fieldsEmpresa"
              :current-page="currentPage"
              :per-page="perPage"
              stacked="md"
              show-empty
              small
              @filtered="onFiltered"
            >
              <template #cell(actions)="row">
                <b-button
                  size="sm"
                  @click="row.toggleDetails"
                >
                  {{ row.detailsShowing ? '-' : '+' }}
                </b-button>
                <!-- Botão Editar Company/Empresa -->
                <b-button
                  class="ml-1"
                  size="sm"
                  @click="modalEditNewCompany(row.item, $event.target)"
                >
                  Editar
                </b-button>
                <b-button
                  class="ml-1"
                  size="sm"
                  @click="deleteCompany(row.item, $event.target)"
                >
                  Deletar
                </b-button>
                <b-button
                  class="ml-1"
                  size="sm"
                  @click.prevent="modalNovoEst(row.item, $event.target)"
                >
                  Novo Estab
                </b-button>
              </template>

              <!-- Tabela Estabelecimentos -->
              <template #row-details="row">
                <b-table
                  :fields="fieldsEstab"
                  :items="row.item.list_branches"
                >

                  // eslint-disable-next-line vue/no-template-shadow
                  <template #cell(actions)="row">
                    <b-button
                      size="sm"
                      @click.prevent="modalEditNewEst(row.item, $event.target)"
                    >
                      Editar Estab
                    </b-button>
                    <b-button
                      class="ml-1"
                      size="sm"
                      @click.prevent="deleteEstab(row.item, $event.target)"
                    >
                      Delete
                    </b-button>
                  </template>

                </b-table>

              </template>
            </b-table>
          </b-overlay>
        </b-col>

        <b-col
          cols="12"
          class="mt-0"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>

      <!-- Nova Empresa Button -->
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :disabled="btnAllowed.disabled"
        variant="outline-primary"
        @click="addNovaEmpresaModal($event.target)"
      >
        Nova Empresa
      </b-button>

      <modal-new-company ref="mnewcompany" />
      <modal-new-branch
        ref="mNewBranch"
      />
    </div>
    <!-- End Main table element -->
  </div>
</template>

<script>
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import ModalNewCompany from '@/views/empresaestab/ModalNewCompany.vue'
import ModalNewBranch from '@/views/empresaestab/ModalNewBranch.vue'
import ComboBoxClient from '@/views/reusable-components/ComboBoxClient.vue'
import {
  BFormSelect, BButton, BCol, BTable, BRow, BPagination, BFormGroup, BOverlay,
} from 'bootstrap-vue'
import ValidateLogonKeycloak from '../reusable-components/ValidateLogonKeycloak.vue'

export default {
  name: 'EmpresaEstab',
  components: {
    BOverlay,
    ModalNewCompany,
    ModalNewBranch,
    ComboBoxClient,
    BFormGroup,
    BRow,
    BPagination,
    BTable,
    BCol,
    BButton,
    BFormSelect,
    ValidateLogonKeycloak,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      btnAllowed: {
        disabled: false,
      },
      item: null,
      show: false,
      selected: null,
      modalNovoEstab: {
        id: 'modalNovoEstab',
        title: '',
        content: '',

      },
      options: [
        { value: null, text: 'Carregando' },
      ],
      selectClient: {
        id: 'select-client',
      },

      fieldsEmpresa: [
        {
          key: 'id_cnpj', label: 'CNPJ Empresa', sortable: true,
        },
        {
          key: 'cod', label: 'COD', sortable: true, sortDirection: 'desc',
        },
        {
          key: 'to_rule', label: 'Para', sortable: true, sortDirection: 'desc',
        },
        {
          key: 'name', label: 'Descrição', sortable: true,
        },

        { key: 'actions', label: 'Actions' },
      ],
      itemsEmpresa: [],

      fieldsEstab: [
        {
          key: 'id_cnpj', label: 'CNPJ Estabelecimento', sortable: true,
        },
        {
          key: 'cod', label: 'COD', sortable: true, sortDirection: 'desc',
        },
        {
          key: 'to_rule', label: 'Para', sortable: true, sortDirection: 'desc',
        },
        {
          key: 'name', label: 'Descrição', sortable: true,
        },
        { key: 'actions', label: 'Actions' },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fieldsEmpresa
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.validateUserLogon()
    // Set the initial number of items
    this.setUser()
    this.totalRows = this.itemsEmpresa.length
    if (!this.validatePermissions()) {
      this.btnAllowed.disabled = true
    }
  },
  methods: {
    validatePermissions() {
      let allowedUser = false
      const userData = JSON.parse(localStorage.getItem('userData'))
      const allowedGroup = userData.group

      if (allowedGroup === 'client_admin' || allowedGroup === 'admin') {
        allowedUser = true
      }

      return allowedUser
    },
    validateUserLogon() {
      this.$refs.validatelogon.validateLogon()
    },
    setUser() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      this.$parent.$parent.$parent.$refs.adenav.username = userData.fullName
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    toast(toaster, append = false, variant, message, title) {
      this.$bvToast.toast(message, {
        title,
        toaster,
        solid: true,
        appendToast: append,
        variant,
      })
    },
    addNovaEmpresaModal(button) {
      this.$refs.mnewcompany.readonly = false
      this.$refs.mnewcompany.novaEmpresa.title = 'Adicionar Nova Empresa'
      this.$refs.mnewcompany.registerButton.content = 'Adicionar Empresa'
      this.$refs.mnewcompany.itemCnpj = ''
      this.$refs.mnewcompany.itemCod = ''
      this.$refs.mnewcompany.itemPara = ''
      this.$refs.mnewcompany.itemDescr = ''

      this.$root.$emit('bv::show::modal', this.$refs.mnewcompany.novaEmpresa.id, button)
    },

    modalNovoEst(item, button) {
      this.show = true // loading page b-overlay
      this.$refs.mNewBranch.readonly = false
      this.$refs.mNewBranch.modalNovoEstab.title = `${item.id_cnpj}`
      this.$refs.mNewBranch.modalNovoEstab.content = 'Adicionar Estabelecimento'
      this.$refs.mNewBranch.registerButton.content = 'Adicionar Estab'
      this.$refs.mNewBranch.itemCnpjEstab = ''
      this.$refs.mNewBranch.itemCodEstab = ''
      this.$refs.mNewBranch.itemParaEstab = ''
      this.$refs.mNewBranch.itemNameEstab = ''
      this.$root.$emit('bv::show::modal', this.modalNovoEstab.id, button)// Faz o modal aparecer
      this.show = false
    },
    modalEditNewCompany(item, button) {
      // mnewcompany refere-se ao nome dado a ref dentro da tag que renderiza o ModalEmpresa
      // novaEmpresa é o id no b-modal dentro do component ModalEmpresa
      this.$refs.mnewcompany.readonly = true
      this.$refs.mnewcompany.novaEmpresa.title = `Editar ${item.id_cnpj}`
      this.$refs.mnewcompany.registerButton.content = 'Editar Empresa'
      this.$refs.mnewcompany.itemCnpj = item.id_cnpj
      this.$refs.mnewcompany.itemCod = item.cod
      this.$refs.mnewcompany.itemPara = item.to_rule
      this.$refs.mnewcompany.itemDescr = item.name
      this.companyId = item.id_cnpj
      this.$root.$emit('bv::show::modal', this.$refs.mnewcompany.novaEmpresa.id, button)// Faz o modal aparecer
    },

    modalEditNewEst(item, button) {
      // mnewcompany refere-se ao nome dado a ref dentro da tag que renderiza o ModalEmpresa
      // novaEmpresa é o id no b-modal dentro do component ModalEmpresa
      this.$refs.mNewBranch.readonly = true
      this.$refs.mNewBranch.modalNovoEstab.title = item.id_cnpj_fk
      this.$refs.mNewBranch.registerButton.content = 'Editar Estab'
      this.$refs.mNewBranch.itemCnpjEstab = item.id_cnpj
      this.$refs.mNewBranch.itemCodEstab = item.cod
      this.$refs.mNewBranch.itemParaEstab = item.to_rule
      this.$refs.mNewBranch.itemNameEstab = item.name

      this.$root.$emit('bv::show::modal', this.$refs.mNewBranch.modalNovoEstab.id, button)// Faz o modal aparecer
    },

    async deleteCompany(fieldsEstab) {
      try {
        const idCnpj = fieldsEstab.id_cnpj
        const res = await axios.delete(`/company/${idCnpj}`)
        console.log(res)
        this.companyTable()
        this.toast('b-toaster-top-right', true, 'success', 'Empresa foi deletada com sucesso!', 'Empresa Excluída')
      } catch (error) {
        console.log(error)
        this.toast('b-toaster-top-right', true, 'danger', ` ${error}`)
      }
    },

    async deleteEstab(fieldsEstab) {
      try {
        const idCnpj = fieldsEstab.id_cnpj
        if (!idCnpj) {
          this.toast('b-toaster-top-right', true, 'danger', 'Cnpj is undefined!')
          return
        }
        await axios.delete(`/branch/${idCnpj}`)
        this.companyTable()
        this.toast('b-toaster-top-right', true, 'success', 'Estabelecimento foi deletado com sucesso!', 'Estabelecimento Excluído')
      } catch (error) {
        console.log(error)
        this.toast('b-toaster-top-right', true, 'danger', ` ${error}`)
      }
    },

    async companyTable() {
      this.show = true // loading page b-overlay
      this.isBusy = true
      try {
        const selectedCnpj = this.$refs.cboxclient.selected
        if (selectedCnpj === null) {
          this.toast('b-toaster-top-right', true, 'danger', 'Selecione um cliente!', 'Erro de usabilidade')
          throw new Error('Selecione um cliente!')
        }

        const res = await axios.get(`/company/${selectedCnpj}`)

        console.log(res)
        this.itemsEmpresa = res.data
        this.totalRows = this.itemsEmpresa.length
        this.currentPage = 1

        console.log(res.data)
      } catch (error) {
        console.log(error)
      }
      this.isBusy = false
      this.show = false
    },
    //   try {
    //     const selectedCnpj = this.$refs.cboxclient.selected
    //     if (selectedCnpj === null) {
    //       this.toast('b-toaster-top-right', true, 'danger', 'Selecione um cliente!', 'Erro de usabilidade')
    //       throw new Error('Selecione um cliente!')
    //     }

    //     const res = await axios.get(`/company/${selectedCnpj}`)
    //     this.itemsEmpresa = res.data
    //     this.totalRows = this.itemsEmpresa.length
    //     this.currentPage = 1
    //     console.log(res)
    //     console.log(res.data)
    //     console.log(adeconnectConfig.testEnv)
    //   } catch (error) {
    //     console.log(error)
    //   }
    //   this.isBusy = false
    //   this.show = false
    // },

  },
}
</script>
