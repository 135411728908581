<template>
  <div>
    <b-modal
      :id="modalNovoEstab.id"
      ref="modalNovoEstab"
      :title="modalNovoEstab.title"
      :content="modalNovoEstab.content"
      hide-footer
    >

      <div>
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>

              <b-col
                md="12"
                mt=""
              >
                <b-form-group>
                  <h4>
                    <b-badge
                      class="mt-1 mb-1"
                      variant="primary"
                    >Dados Estabelecimento
                    </b-badge>
                  </h4>

                  <!-- CNPJ Estabelecimento -->

                  <label>CNPJ Estabelecimento</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required|digits:14"
                    name="CNPJ Estab"
                  >
                    <b-form-input
                      v-model="itemCnpjEstab"
                      :state="errors.length > 0 ? false:null"
                      placeholder="CNPJ Estabelecimento"
                      :readonly="readonly"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Codigo-->
              <b-col md="12">
                <b-form-group>
                  <label>Codigo</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Cod Estab"
                  >
                    <b-form-input
                      v-model="itemCodEstab"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Codigo"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!--  Descrição -->
              <b-col md="12">
                <b-form-group>
                  <label>Descrição</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Descr"
                  >
                    <b-form-input
                      v-model="itemNameEstab"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Descrição"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Para-->
              <b-col md="12">
                <b-form-group>
                  <label>Para</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Para"
                  >
                    <b-form-input
                      v-model="itemParaEstab"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Para"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Buttons -->
              <b-col class="demo-inline-spacing">

                <!-- Adicionar Novo Estabelecimento button -->
                <b-button
                  :id="registerButton.id"
                  :content="registerButton.content"
                  type="submit"
                  size=""
                  @click.prevent="validationForm()"
                >
                  {{ registerButton.content }}
                </b-button>

              </b-col>
            </b-row>
          </b-form>
        </validation-observer>

      </div>
    </b-modal>
    <b-modal
      id="modal-edit-estab"
      title="Editar estab"
      hide-footer
    >
      <!-- ... -->

      <!-- Editar Estab button -->
      <b-button
        id="editarEstab"
        variant="primary"
        type="submit"
        @click.prevent="validationForm()"
      >
        Editar Estab
      </b-button>

      <!-- ... -->
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import {
  BButton, BModal, BFormGroup, BForm, BRow, BCol, BFormInput, BBadge,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'ModalNewBranch',
  components: {
    BBadge,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BForm,
    BRow,
    BCol,
    BButton,
    BModal,
  },

  data() {
    return {
      readonly: false,
      modalNovoEstab: {
        id: 'modalNovoEstab',
        title: 'Editar Estab',
        content: '',
      },
      registerButton: {
        id: 'register-button',
        content: 'Adicionar Estab',
      },
      selectedCnpj: '',
      required,
      itemCnpjEstab: null,
      itemCodEstab: null,
      itemParaEstab: null,
      itemNameEstab: null,

    }
  },

  methods: {
    toast(toaster, append = false, variant, message, title) {
      this.$bvToast.toast(message, {
        title,
        toaster,
        solid: true,
        appendToast: append,
        variant,
      })
    },
    validationForm() {
      this.readonly = false
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.modalNovoEstab.content === 'Adicionar Estabelecimento') {
            this.addBranch()
          } else {
            this.updateBranch()
          }
        }
      })
    },
    async addBranch() {
      try {
        // Getting values for POST
        const companyCnpj = this.modalNovoEstab.title
        console.log(companyCnpj)
        const dataEstab = {
          id_cnpj: this.itemCnpjEstab,
          cod: this.itemCodEstab,
          to_rule: this.itemParaEstab,
          name: this.itemNameEstab,
        }
        // End getting values for post

        await axios.post(`/branch/${companyCnpj}`, dataEstab)
          .then(response => {
            this.toast('b-toaster-top-right', true, 'success', `Estabelecimento ${this.itemNameEstab} cadastrada com sucesso`, 'Cadastro Estabelecimento')
            console.log(response)
          })
        // Atualizar table
        this.$parent.companyTable()
        // deixa o campos em branco após salvo.
        this.itemCnpjEstab = ''
        this.itemCodEstab = ''
        this.itemParaEstab = ''
        this.itemNameEstab = ''
        // hide (fechar o modal após o item ser postado)
        this.$refs.modalNovoEstab.hide()
      } catch (error) {
        console.log(error)
      }
    },
    async updateBranch() {
      try {
        const idCnpjEmpresa = this.modalNovoEstab.title
        console.log(this.modalNovoEstab.title)
        await axios.patch(`/branch/update/${idCnpjEmpresa}`, {
          id_cnpj: this.itemCnpjEstab,
          cod: this.itemCodEstab,
          to_rule: this.itemParaEstab,
          name: this.itemNameEstab,

        })

        this.toast('b-toaster-top-right', true, 'success', 'Estabelecimento atualizado com sucesso.', 'Atualização Estabelecimento')
        // "this.toast"onde chama o metodo que esta acima qndo se clica no botão.
        // foi colocado aqui pois não é correto adicionar 2 eventos no onclick, então chamamos um método dentro do outro, toast
        // dentro do addClick
        this.$refs.modalNovoEstab.hide() // esconde o modal se cadastrado com sucesso.
        this.$parent.companyTable()
      } catch (error) {
        if (error.response.status === 500) {
          this.toast('b-toaster-top-right', true, 'danger', ` ${error}`)
        } else {
          this.toast('b-toaster-top-right', true, 'warning', ` ${error}`, 'Cadastro Estabelecimento')
        }
      }
    },
  },
}
</script>
