<!-- eslint-disable camelcase -->
<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <!-- Profile Menu -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <p class="user-name font-weight-bolder mb-0">
          {{ username }}
        </p>
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ username }}
            </p>
            <p class="user-status">
              {{ group }}
            </p>
          </div>
        </template>
        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout()"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  components: {
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      username: 'NoOne',
      group: '',
    }
  },
  mounted() {
    // Obtenha o valor de userRole do localStorage
    const userRole = JSON.parse(localStorage.getItem('userData')).group

    if (userRole === 'client_admin') {
      this.group = 'admin'
    } else if (userRole === 'admin') {
      this.group = 'adejo'
    } else {
      this.group = 'operator'
    }
  },
  methods: {
    logout() {
      localStorage.clear()
      this.$router.push('/')
    },
  },
}
</script>
