var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"my-modal",attrs:{"id":_vm.clientModal.id,"title":_vm.clientModal.title,"content":_vm.clientModal.content,"hide-footer":"","no-close-on-backdrop":""}},[_c('br'),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v("Dados do Cliente ")]),_c('br'),_c('br'),_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required|digits:14","name":"CNPJ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"CNPJ","readonly":_vm.readonly},model:{value:(_vm.itemId_cnpj),callback:function ($$v) {_vm.itemId_cnpj=$$v},expression:"itemId_cnpj"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required","name":"Cod"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Codigo"},model:{value:(_vm.itemCod),callback:function ($$v) {_vm.itemCod=$$v},expression:"itemCod"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required","name":"Nome"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Nome"},model:{value:(_vm.itemName),callback:function ($$v) {_vm.itemName=$$v},expression:"itemName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"E-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Email"},model:{value:(_vm.itemEmail),callback:function ($$v) {_vm.itemEmail=$$v},expression:"itemEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required","name":"Telefone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Telefone"},model:{value:(_vm.itemTelephone),callback:function ($$v) {_vm.itemTelephone=$$v},expression:"itemTelephone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('br'),_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(" Tax One ")]),_c('br'),_c('br'),_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required","name":"Tr_user"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Tr_user"},model:{value:(_vm.itemTr_user),callback:function ($$v) {_vm.itemTr_user=$$v},expression:"itemTr_user"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required","name":"Tr_passwd"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password","type":"password","state":errors.length > 0 ? false:null,"placeholder":"Tr_passwd"},model:{value:(_vm.itemTr_passwd),callback:function ($$v) {_vm.itemTr_passwd=$$v},expression:"itemTr_passwd"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('br'),_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(" Tipo de Integração ")]),_c('br'),_c('br'),_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required","name":"Arquivo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.options},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"demo-inline-spacing"},[_c('b-button',{attrs:{"id":"testConection","variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();}}},[_vm._v(" Testar Conexão ")]),_c('b-button',{attrs:{"id":_vm.registerButton.id,"content":_vm.registerButton.content,"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" "+_vm._s(_vm.registerButton.content)+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }