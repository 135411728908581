<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Processamentos Mês </b-card-title>

      <!-- <b-card-title>$74,123</b-card-title> -->

      <!-- datepicker Icon -->
      <!-- <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div> -->
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-horizontal-bar-chart
        v-if="graficoData2.labels.length && graficoData2.datasets[0].data.length"
        :key="chartKey"  
        :height="400"
        :data="graficoData2"
        :options="chartjsData.horizontalBarChart.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
// import axios from 'axios'
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentHorizontalBarChart from '../charts-components/ChartjsComponentHorizontalBarChart.vue'
import chartjsData from '../chartjsData'

export default {
  props: {
    graficoData2: {
      type: Object,
      required: true,
    },
  },
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    // BCardSubTitle,
    flatPickr,
    ChartjsComponentHorizontalBarChart,
  },
  data() {
    return {
      chartKey: 0, // Inicializa uma key para o gráfico
      chartjsData,
      graficoData2: {
      labels: [],
      datasets: []
       },
      //rangePicker: ['2019-05-01', '2019-05-10'],
    }
  },
  watch: {
    graficoData2: {
      handler(newData) {
        // Atualiza a key sempre que graficoData2 mudar
        this.chartKey += 1
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
