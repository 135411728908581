<!-- eslint-disable no-shadow -->
<template>
  <div>

    <b-row>
      <b-col class="mb-1">
        <b-form-group>
          <b-row>
            <b-col cols="4">
              <b-form-group
                description="Selecione o CNPJ."
              >
                <combo-box-client
                  ref="cboxclient"
                />
              </b-form-group>
            </b-col>

            <b-col cols="4">
              <b-form-group>
                <b-button
                  variant="primary"
                  type="button"
                  :disabled="!isAdmin"
                  @click="userTable()"
                >
                  Carregar
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-overlay
          :show="show"
          spinner-variant="primary"
          spinner-type="border"
          spinner-large
          rounded="lg"
        >
          <b-table
            ref="btable_users"
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items_users"
            :fields="fields"
          >
            <!-- coluna actions botões Edit e Delete-->
            <template #cell(actions)="row">

              <!-- Action Buttons -->
              <b-button-toolbar>

                <b-button-group size="sm">

                  <!-- Edit Button -->
                  <b-button
                    class=""
                    size="sm"
                    @click="editUser(row.item, $event.target)"
                  >
                    EDIT
                  </b-button>
                  <!-- Delete Button -->
                  <b-button
                    size="sm"
                    @click.prevent="modalBeforeDel(row.item, $event.target)"
                  >
                    DELETE
                  </b-button>
                </b-button-group>
              </b-button-toolbar>
            </template>
          </b-table>
        </b-overlay>
        <!-- Delete Modal  -->
        <div>
          <b-modal
            :id="modalDelete.id"
            ref="modal-delete"
            :title="modalDelete.title"
            :content="modalDelete.content"
            button-size="sm"
            hide-footer
          >
            <div>
              <!-- Como renderizar o cnpj específico que foi clicado? -->
              Tem certeza que deseja excluir este usuário?
            </div>

            <!-- Botão cancelar dentro do botão delete -->
            <b-col class="demo-inline-spacing">
              <b-button
                id=""
                size="sm"
                variant="primary"
                type="button"
                @click="hideModal1()"
              >
                Cancelar
              </b-button>

              <!-- Excluir button -->
              <b-button
                id="deleteButton"
                size="sm"
                variant="primary"
                type="button"
                @click.prevent=""
              >
                Excluir
              </b-button>
            </b-col>
          </b-modal>
        </div>
      </b-col>

      <b-col
        cols="12"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
    <b-button

      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      :disabled="!isAdmin"
      @click="addUsersModal()"
    >
      Cadastrar novo usuário
    </b-button>

    <modal-new-users ref="musers" />
    <validate-logon-keycloak ref="validatelogon" />
  </div>
</template>

<script>
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import ModalNewUsers from '@/views/Users/ModalNewUsers.vue'
import ValidateLogonKeycloak from '@/views/reusable-components/ValidateLogonKeycloak.vue'

import {
  BFormGroup,
  BPagination,
  BButtonToolbar,
  BButtonGroup,
  BOverlay,
  BTable,
  BRow,
  BCol,
  BButton,

} from 'bootstrap-vue'
import ComboBoxClient from '../reusable-components/ComboBoxClient.vue'

export default {
  name: 'UserKeycloak',
  components: {
    ComboBoxClient,
    ValidateLogonKeycloak,
    BPagination,
    ModalNewUsers,
    BFormGroup,
    BButtonToolbar,
    BButtonGroup,
    BOverlay,
    BTable,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isAdmin: false, // Add isAdmin property to keep track of admin status
      checked: false,
      show: false,
      perPage: 10,
      pageOptions: [5, 10, 20, 30],
      totalRows: 1,
      currentPage: 1,
      modalDelete: {
        id: 'modalDelete',
        title: '',
        content: 'Excluir',
      },
      fields: [
        {
          key: 'username', label: 'Username', sortable: true,
        },
        {
          key: 'firstName', label: 'NOME', sortable: true,
        },
        {
          key: 'lastName', label: 'Sobrenome', sortable: true,
        },
        {
          key: 'email', label: 'Email', sortable: true,
        },
        {
          key: 'enabled', label: 'status', sortable: true, formatter: value => (value ? 'ativo' : 'inativo'),
        },
        { key: 'actions', label: 'Actions' },
      ],
      items_users: [],
    }
  },
  mounted() { // Mounted has to be used when using a Child and trigger after the import
    this.validateUserLogon()
    this.setUser()
  },
  created() {
    this.createUsersTable()
  },

  methods: {

    validateUserLogon() {
      this.$refs.validatelogon.validateLogon()
    },
    setUser() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      this.$parent.$parent.$parent.$refs.adenav.username = userData.fullName
    },
    modalBeforeDel(item, button) {
      this.modalDelete.title = 'Deletar Usuário'

      this.$root.$emit('bv::show::modal', this.modalDelete.id, button)// Faz o modal aparecer
    },
    hideModal1() {
      this.$refs['modal-delete'].hide()
    },
    toast(toaster, append = false, variant, message, title) {
      this.$bvToast.toast(message, {
        title,
        toaster,
        solid: true,
        appendToast: append,
        variant,
      })
    },
    opcaoTodos() {
      const selectedCnpj = this.$refs.cboxclient.selected
      if (selectedCnpj === 'todos') {
        // Reinicializar a página
        // window.location.reload()
        this.createUsersTable()
        console.log('chamou createUsersTable')
      }
    },

    async createUsersTable() {
      this.show = true // loading page b-overlay
      this.isBusy = true

      // Get the role information from local storage
      const userRole = JSON.parse(localStorage.getItem('userData')).group

      // Check if the user is an admin
      if (userRole === 'operator') {
        // Handle the case where non-admin users don't have access to the data
        console.log('You do not have permission to view this data.')
        this.isBusy = false
        this.show = false
        this.isAdmin = false // Set isAdmin to false for non-admin users
        return
      }

      this.isAdmin = true // Set isAdmin to true for admin users

      const CNPJValue = JSON.parse(localStorage.getItem('userData')).cnpj
      console.log(CNPJValue)

      // If the user is an admin, proceed with the API call

      if (userRole === 'client_admin') {
        const cnpjsIguais = []
        const res = await axios.post('/auth/getusers', CNPJValue)
        const resData = res.data
        // loop for para percorrer cada objeto no array
        for (let i = 0; i < resData.length; i += 1) {
          // Acessa a propriedade 'attributes' de cada objeto
          const { attributes } = resData[i]

          // Verifica se 'attributes' existe e se 'CNPJ' está presente
          if (attributes && attributes.CNPJ) {
            // Adiciona o CNPJ ao novo array
            const cnpjs = attributes.CNPJ[0]

            // Compara o CNPJ com o CNPJValue
            if (cnpjs === CNPJValue) {
              cnpjsIguais.push(cnpjs)
              console.log(cnpjs)
              console.log(cnpjsIguais)
            }
          }
        }
        // Atualiza this.items_users apenas com os CNPJs iguais
        this.items_users = cnpjsIguais
      }
      // Getting values for POST
      // const CNPJ = ['string']
      try {
        const res = await axios.post('/auth/getusers', CNPJValue)
        this.items_users = res.data
        console.log(res.data)
        this.totalRows = this.items_users.length
        this.currentPage = 1
      } catch (error) {
        if (error.response) {
          console.log('Status code:', error.response.status)
          console.log('Response data:', error.response.data)
        }
        if (error.request) {
          console.log('Request:', error.request)
        }
        console.log('Config:', error.config)
      }
      this.isBusy = false
      this.show = false
    },

    // trazendo dados usuários
    async userTable() {
      this.show = true // loading page b-overlay
      this.opcaoTodos()

      try {
        const CNPJValue = JSON.parse(localStorage.getItem('userData')).cnpj
        const res = await axios.post('/auth/getusers', CNPJValue)
        const resData = res.data
        console.log(resData)
        // Cria um novo array para armazenar apenas os CNPJs
        const cnpjs = []

        // loop for para percorrer cada objeto no array
        for (let i = 0; i < resData.length; i += 1) {
          // Acessa a propriedade 'attributes' de cada objeto
          const { attributes } = resData[i]

          // Verifica se 'attributes' existe e se 'CNPJ' está presente
          if (attributes && attributes.CNPJ) {
            // Adiciona o CNPJ ao novo array
            cnpjs.push(attributes.CNPJ[0])
          }
        }

        // Agora, 'cnpjs' conterá todos os CNPJs do array de objetos
        console.log(cnpjs)

        const selectedCnpj = this.$refs.cboxclient.selected
        if (selectedCnpj === null) {
          this.toast('b-toaster-top-right', true, 'danger', 'Selecione um cliente!', 'Erro de usabilidade')
          throw new Error('Selecione um cliente!')
        } if (cnpjs.includes(selectedCnpj)) {
          // Filtra os objetos em resData com o CNPJ correspondente
          this.items_users = resData.filter(item => {
            const { attributes } = item
            return attributes && attributes.CNPJ && attributes.CNPJ[0] === selectedCnpj
          })

          this.totalRows = this.items_users.length
          this.currentPage = 1
          console.log(this.items_users)
        }
      } catch (error) {
        if (error.response) {
          console.log('Status code:', error.response.status)
          console.log('Response data:', error.response.data)
        }
        if (error.request) {
          console.log('Request:', error.request)
        }
        console.log('Config:', error.config)
      }

      this.show = false
    },
    // async deleteClient() {
    //   try {
    //     await axios.delete(`/companyuser/${this.modalDelete.title}`)
    //     this.toast('b-toaster-top-right', true, 'success', 'Cliente foi deletado com sucesso!', 'Cliente Excluído')
    //     // "this.toast"onde chama o metodo que esta acima qndo se clica no botão.
    //     // foi colocado aqui pois não é correto adicionar 2 eventos no onclick, então chamamos um método dentro do outro, toast
    //     // dentro do addClick
    //     this.createTable()
    //     this.hideModal1()
    //   } catch (error) {
    //     console.log(error)
    //     this.toast('b-toaster-top-right', true, 'danger', ` ${error}`)
    //     if (error.response.status === 500) {
    //       this.toast('b-toaster-top-right', true, 'danger', ` ${error}`)
    //     } else {
    //       this.toast('b-toaster-top-right', true, 'warning', `CNPJ ${this.modalDelete.title} não encontrado`, 'Deletar Cliente')
    //     }
    //   }
    // },
    // addModal é a função que está no botão cadastrar cliente linha 194 e tem como objetivo fazer o
    // cont. modal voltar ao normal depois que aperta o edit
    async addUsersModal(button) {
      const userRole = JSON.parse(localStorage.getItem('userData')).group
      await this.$refs.musers.getClient()
      // musers refere-se ao nome dado a ref dentro da tag que renderiza o ModalNewUsres
      // usersModal é o id no b-modal dentro do component ModalNewUsres
      if (userRole === 'client_admin' || userRole === 'operator') {
        const adminIndex = this.$refs.musers.options1.findIndex(option => option.value === 'ADMIN')

        if (adminIndex !== -1) {
          this.$refs.musers.options1.splice(adminIndex, 1)
        }
      }

      this.$refs.musers.readonly = false
      this.$refs.musers.readonly = false
      this.$refs.musers.usersModal.title = 'Cadastro de Usuário'
      this.$refs.musers.registerButton.content = 'Cadastrar'
      this.$refs.musers.itemUsername = ''
      this.$refs.musers.itemEmail = ''
      this.$refs.musers.itemFirstName = ''
      this.$refs.musers.itemLastName = ''
      this.$refs.musers.itemCnpj = ''
      this.$refs.musers.itemSelected = ''
      this.$refs.musers.itemPasswd = ''
      this.$root.$emit('bv::show::modal', this.$refs.musers.usersModal.id, button)// Faz o modal aparecer
    },
    async editUser(item, button) {
      await this.$refs.musers.getClient()
      this.$refs.musers.readonly = true
      this.$refs.musers.usersModal.title = `${item.id}`
      this.$refs.musers.registerButton.content = 'Atualizar'
      this.$refs.musers.itemUsername = item.username
      this.$refs.musers.itemEmail = item.email
      this.$refs.musers.itemFirstName = item.firstName
      this.$refs.musers.itemLastName = item.lastName

      // item.attributes.CNPJ is the input array
      const inputArray = item.attributes.CNPJ

      // Convert the array to an object array with the desired format
      const outputArray = inputArray.map(cnpj => ({
        value: cnpj,
        label: `${cnpj}`,
      }))

      this.$refs.musers.itemCnpj = outputArray
      console.log(outputArray)

      // eslint-disable-next-line prefer-destructuring
      this.$refs.musers.itemSelected = item.attributes.ROLE[0]
      this.$refs.musers.itemPasswd = item.user_password

      this.$root.$emit('bv::show::modal', this.$refs.musers.usersModal.id, button)// Faz o modal aparecer
    },
  },
}
</script>
