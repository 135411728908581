var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal-new-user",attrs:{"id":_vm.usersModal.id,"title":_vm.usersModal.title,"content":_vm.usersModal.content,"no-close-on-backdrop":"","hide-footer":""}},[_c('br'),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v("Dados do Usuário ")]),_c('br'),_c('br'),_c('label',[_vm._v("Username")]),_c('validation-provider',{attrs:{"rules":"required","name":"Username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Username","readonly":_vm.readonly},model:{value:(_vm.itemUsername),callback:function ($$v) {_vm.itemUsername=$$v},expression:"itemUsername"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("E-mail")]),_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Email"},model:{value:(_vm.itemEmail),callback:function ($$v) {_vm.itemEmail=$$v},expression:"itemEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Nome")]),_c('validation-provider',{attrs:{"rules":"required","name":"Nome"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Nome"},model:{value:(_vm.itemFirstName),callback:function ($$v) {_vm.itemFirstName=$$v},expression:"itemFirstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Sobrenome")]),_c('validation-provider',{attrs:{"rules":"required","name":"Sobrenome"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Sobrenome"},model:{value:(_vm.itemLastName),callback:function ($$v) {_vm.itemLastName=$$v},expression:"itemLastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Senha")]),_c('validation-provider',{attrs:{"rules":"required","name":"Senha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"password","state":errors.length > 0 ? false:null,"placeholder":"Senha"},model:{value:(_vm.itemPasswd),callback:function ($$v) {_vm.itemPasswd=$$v},expression:"itemPasswd"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Empresa / CNPJ"}},[_c('validation-provider',{attrs:{"rules":"required","name":"cnpj"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":"Selecione CNPJ","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.options,"state":errors.length > 0 ? false:null,"multiple":""},on:{"input":function($event){return _vm.getClient()}},model:{value:(_vm.itemCnpj),callback:function ($$v) {_vm.itemCnpj=$$v},expression:"itemCnpj"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',[_c('b-row',[_c('b-col',{staticClass:"ml-1"},[_c('b-form-group',{attrs:{"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('validation-provider',{attrs:{"rules":"required","name":"radio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":_vm.options1,"state":errors.length > 0 ? false:null,"aria-describedby":ariaDescribedby,"name":"radio"},model:{value:(_vm.itemSelected),callback:function ($$v) {_vm.itemSelected=$$v},expression:"itemSelected"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})]}}])}),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"name":"switch","switch":""},model:{value:(_vm.itemEnable),callback:function ($$v) {_vm.itemEnable=$$v},expression:"itemEnable"}},[_vm._v(" "+_vm._s(_vm.checkboxText)+" ")])],1)],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{attrs:{"id":_vm.registerButton.id,"content":_vm.registerButton.content,"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" "+_vm._s(_vm.registerButton.content)+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }