<template>
  <div>
    <!-- MODAL para adicionar novo de/para -->
    <b-modal
      :id="agendamento.id"
      ref="modalAgendamento"
      :title="agendamento.title"
      hide-footer
      no-close-on-backdrop
    >
    <b-overlay
          :show="show"
          spinner-variant="primary"
          spinner-type="border"
          spinner-large
          rounded="lg"
        >
      <div>
        
        <validation-observer ref="newRules">
          <b-form>
            <b-row>
              <b-col md="12">
                <!-- Cod Empresa -->

                <!-- <b-form-group
                  label="Cod Empresa"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Cod Empresa"
                  >
                    <v-select
                      v-model="selected_empresa"
                      placeholder="Selecione Empresa"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="options_empresa"
                      :state="errors.length > 0 ? false:null"
                      @input="getEstab()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group> -->

                <!-- Cod Estabelecimento -->
                <b-form-group
                  label=""
                >
                  <!-- <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Cod Estabelecimento"
                  >
                    <b-overlay
                      :show="show"
                      spinner-variant="primary"
                      spinner-type="border"
                      spinner-large
                      rounded="lg"
                    >
                      <div class="checkbox-group-container">

                        <b-form-checkbox-group
                          v-if="selected_empresa"
                          v-model="selected_estab"
                          :options="options_estab"
                          stacked
                        />

                      </div>
                      <div class="mt-2 form-check">
                        <b-form-checkbox
                          v-model="allSelected"
                          :indeterminate="indeterminate"
                          @change="toggleAll"
                        >
                          Select All
                        </b-form-checkbox>
                      </div>

                    </b-overlay>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                  <div
                    v-if="selected_empresa"
                    class="mt-2"
                  >
                    Selected: <strong>{{ selected_estab }}</strong>
                  </div> -->
                  <br>
                  <h4><b-badge variant="primary">
                    Agendamento
                  </b-badge></h4>

                  <b-form-group
                    class="mt-2"
                    label="SAFX"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="SAFX"
                    >
                      <v-select
                        v-model="selected_safx"
                        placeholder="Selecione SAFX"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="options_safx"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <!-- Frequência -->

                  <b-form-group
                    label="Frequência"
                    class="mt-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Frequência"
                    >
                      <v-select
                        v-model="selected_freq"
                        placeholder="Selecione Frequência"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="options_freq"
                        :state="errors.length > 0 ? false:null"
                        @input="handleFrequencyChange"
                        
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Exibe o seletor de dia do mês somente se "Dia do Mês" estiver selecionado -->
                  <b-form-group v-if="showDayOfMonth" class="mt-2" label="Dia do Mês">
                     <v-select
                       v-model="selected_day"
                       placeholder="Selecione o Dia do Mês"
                       :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                       :options="options_days"
                     />
                  </b-form-group>

                  <!-- <b-form-group
                    label="Frequência"
                    class="mt-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Frequência"
                    >
                      <v-select
                        v-model="selected_freq"
                        placeholder="Selecione Frequência"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="options_freq"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group> -->

                  <!-- Horário -->
                  <b-form-group
                    label="Horário"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Horário"
                    >
                    <v-select
                        v-model="selected_time"
                        placeholder="Selecione Horário"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="options_time"
                        :state="errors.length > 0 ? false:null"
                      />
                      <b-input-group class="">
                      

                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <br>
                  <b-badge variant="primary">Integração via arquivo
                  </b-badge>
                  <br>
                  <br>
                  <b-form-group
                    v-slot="{ ariaDescribedby }"
                    label=""
                  >
                    <b-form-checkbox-group
                      id="checkbox-group-2"
                      v-model="selected"
                      :aria-describedby="ariaDescribedby"
                      name=""
                    >
                      <b-form-checkbox
                        v-b-tooltip.hover.topright="'Caso a integração via arquivos não utilize filtros de empresa e estabelecimento'"
                        value="use_filter"
                      >
                        Não utilizar filtros
                      </b-form-checkbox>
                      <br>
                      <br>
                      <b-form-checkbox
                        v-b-tooltip.hover.topright="'Deleta todos os arquivos disponíveis no diretório de upload'"
                        value="delete_files"
                      >
                        Deletar arquivos carregados
                      </b-form-checkbox>

                    </b-form-checkbox-group>
                  </b-form-group>

                  <!-- <div>Selected: <strong>{{ selected }}</strong></div> -->
                </b-form-group>
              </b-col>
              <b-col class="demo-inline-spacing">
                <b-button
                  id=""
                  variant="primary"
                  type="button"
                  @click="hidemodalAgendamento()"
                >
                  Cancelar
                </b-button>

                <!-- Adicionar Nova Regra button -->
                <b-overlay
                  :show="show"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                  class="d-inline-block"
                >
                  <b-button
                    id="executButton"
                    variant="primary"
                    type="button"
                    @click="validationFormAgendar"
                  >
                    Agendar
                  </b-button>
                </b-overlay>

              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      
      </div>
    </b-overlay>
    </b-modal>

  </div>
</template>

<script>
import axios from 'axios'
import vSelect from 'vue-select'
import {
  VBTooltip, BBadge, BButton, BModal, BFormTimepicker, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BForm, BRow, BCol, BOverlay, BInputGroup, BInputGroupAppend, BFormInput, BFormDatalist
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'ModalAgendamento',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BBadge,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormTimepicker,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BForm,
    BRow,
    BCol,
    BButton,
    BModal,
    BFormDatalist
  },
  data() {
    return {
      tabAgendamento: {
        id: 'tabAgendamento',
        title: '',
        content: '',
      },
      agendamento: {
        id: 'agendamento',
        title: 'Agendamento',
        content: '',
      },
      selected: [],
      allSelected: false,
      indeterminate: false,
      modalDag: {
        id: 'modalDag',
        title: '',
        content: '',
      },
      show: false,
      selected_empresa: '',
      selected_estab: [],
      selected_safx: '',
      selected_freq: null,
      selected_day: null,
      selected_time:'',
      options_time: ['1', '2', '3', '4','5','6', '7', '8','9','10','11','12','13','14','15', '16','17', '18','19','20','21','22','23','00'],
      id_conversion: null,
      readonly: true,
      // options_empresa: [
      //   { value: null, label: 'Selecione a Empresa' },
      // ],
      // options_estab: [
      //   { value: null, label: 'Selecione a Estab' },
      // ],
      options_safx: [
        { value: null, label: 'Selecione a Safx' },
      ],
      options_freq: [
         'Diária' , 'Dia do Mês'
        
      ],
      options_days: Array.from({ length: 31 }, (_, i) => ({ value: i + 1, label: (i + 1).toString() })),
      showDayOfMonth: false, // Controle para mostrar/esconder o seletor de dia do mês
      required,
    }
  },
  // Select all checkbox (button)
  watch: {
    // selected_estab(newValue) {
    //   // Handle changes in individual checkboxes
    //   if (newValue.length === 0) {
    //     this.indeterminate = false
    //     this.allSelected = false
    //   } else if (newValue.length === this.options_estab.length) {
    //     this.indeterminate = false
    //     this.allSelected = true
    //   } else {
    //     this.indeterminate = true
    //     this.allSelected = false
    //   }
    // },
  },

  created() {
    this.getEmpresaAg()
    this.getSAFX()
  },
  methods: {
    handleFrequencyChange(selectedOption) {
      const value = selectedOption // Acessa o valor da opção selecionada
      
      console.log("Função  handleFrequencyChange chamada")
      console.log('Valor selecionado:', value) // Mostra o valor selecionado
      
      this.selected_freq = value

    // Mostra ou esconde o seletor de dia do mês com base na opção escolhida
      if (value === 'Dia do Mês') {
      this.showDayOfMonth = true
      console.log('Opção "Dia do Mês" selecionada. Mostrando seletor de dia do mês.'); // Log quando a condição é verdadeira
      } else {
     this.showDayOfMonth = false
      console.log('Opção "Diária" selecionada. Ocultando seletor de dia do mês.'); // Log quando a condição é falsa
      this.selected_day = null // Reseta a seleção de dia
    }

    
   
  },
    // toggleAll(checked) {
    //   this.selected_estab = checked ? this.options_estab.map(option => option.value) : []
    // },
    toast(toaster, append = false, variant, message, title) {
      this.$bvToast.toast(message, {
        title,
        toaster,
        solid: true,
        appendToast: append,
        variant,
      })
    },

    validationFormAgendar() {
      this.$refs.newRules.validate().then(success => {
        if (success) {
          this.postAgendamento()
          // hide (fechar o modal após o item ser postado)
        }
      })
    },
    convertToCronExpression() {

      
      // Obtenha o valor diretamente do objeto da opção escolhida
      const frequencyField = this.selected_freq === 'Dia do Mês' ? 'monthly' : '*'
   
      console.log('Frequency Field:', frequencyField)

      // Mapeie o valor de valueTime para o campo correspondente da expressão cron (hora)

      //const hourField = this.valueTime
      const hourField = this.selected_time

      let dayField= '*'

      // Se a frequência for "Dia do Mês", utilize o valor selecionado para o dia
      if (frequencyField === 'monthly') {
        
        dayField = this.selected_day ? this.selected_day.value : '*' // Acesse o valor do dia, se definido
     
        console.log('Frequência:',frequencyField)
        console.log('Dia selecionado:', dayField)
      } else {
        dayField = '*' // Caso contrário, mantenha como '*'
        
      }

      // Monta a expressão cron
      const cronExpression = `0 ${hourField} ${dayField} * *`

      console.log('Cron Expression:', cronExpression)

      // Retorne a expressão cron ou utilize-a conforme necessário em seu código
      return cronExpression
    },

    postAgendamento() {
      this.show = true
      try {
        // Getting values for POST
        const cnpjSelected = this.agendamento.title
        console.log(`${cnpjSelected}cnpj`)

        const safxLabel = this.selected_safx.label.split(' ')
        const safxSelected = safxLabel[0]
        console.log(`${safxSelected} safxSelected`)
        const safxSelected2 = this.selected_safx.value
        console.log(`${safxSelected2} safxSelected`)

        // const empresaSelected = this.selected_empresa.value.COD
        // console.log(`${empresaSelected}empresaSelected`)

        // const estabValues = this.selected_estab
        // console.log(`${estabValues}estabSelected`)

        const data = {
          frequency: this.convertToCronExpression(),
          use_filter: this.selected.includes('use_filter'), // Verifica se 'use_filter' está presente no array
          delete_files: this.selected.includes('delete_files'), // Verifica se 'delete_files' está presente no array
        }

        // End getting values for post

        axios.post(`/scheduler/${cnpjSelected}/${safxSelected2}`, data)
          .then(response => {
            this.toast('b-toaster-top-right', true, 'success', 'Agendamento efetuado com sucesso', 'Agendamento SAFX')
            console.log(response)

            // deixa campos em branco
            this.selected_safx = ''
            this.selected_freq = ''
            this.selected_day = ''
            this.selected_time = []
            this.$parent.agTable()
            this.show = false
            this.$refs.modalAgendamento.hide()
          })
          .catch(error => {
            // Exibe o erro através do toast
            this.toast('b-toaster-top-right', true, 'danger', `Erro na solicitação: ${error.message}`, 'Agendamento SAFX')
            console.error('Erro na solicitação POST:', error)
            // Você pode adicionar tratamento de erro adicional aqui, se necessário
          })
      } catch (error) {
        console.log(error)
        
      }
      
    },
    async getSAFX() {
      try {
        const responseFiltered = []
        const resSafx = await axios.get('/safx/')

        resSafx.data.forEach(item => {
          responseFiltered.push({
            value: item.id_safx,
            label: `${item.table_name} - ${item.desc}`,
          })
        })
        this.options_safx = responseFiltered
      } catch (error) {
        console.log(error)
      }
    },
    async getEstab() {
      this.show = true // loading page b-overlay
      try {
        const responseFiltered = []
        const getEstabFromEmpr = this.selected_empresa.value.CNPJ
        console.log(getEstabFromEmpr)
        const resEstab = await axios.get(`/branch/${getEstabFromEmpr}`)

        resEstab.data.forEach(item => {
          responseFiltered.push({
            value: item.cod,
            label: `${item.cod} - ${item.name}`,
            text: `${item.cod} - ${item.name}`,
          })
        })
        this.options_estab = responseFiltered
        // this.totalRows2 = this.row.item.runs.length
        // this.currentPage2 = 1
      } catch (error) {
        console.log(error)
      }
      this.show = false
    },
    hidemodalAgendamento() {
      this.selected_empresa = ''
      this.selected_estab = []
      this.valueDataIni = ''
      this.valueDataFim = ''
      this.selected_day = ''
      this.$refs.modalAgendamento.hide()
    },
    async getEmpresaAg() {
      try {
        const responseFiltered = []
        const cnpjSelected = this.agendamento.title
        console.log(cnpjSelected)
        const resEmpresa = await axios.get(`/company/${cnpjSelected}`)
        console.log(resEmpresa)
        resEmpresa.data.forEach(item => {
          responseFiltered.push({
            value: { CNPJ: item.id_cnpj, COD: item.cod },
            label: `${item.id_cnpj} - ${item.cod} -${item.name}`,
          })
        })
        this.showEstab = true
        this.options_empresa = responseFiltered
      } catch (error) {
        console.log(error)
      }
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.checkbox-group-container {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 300px;

}
</style>
