<template>
  <div>

    <b-card title="AdeConnect 🔒" />
    <div>
      
      <b-container class="bv-example-row">
        <!-- ComboBox -->
        <b-row>
          <b-col
            class="mb-1"
            cols="4"
          >
            <b-form-group
              description="Selecione o CNPJ."
            >
              <combo-box-client ref="cboxclient"  @options-loaded="showFirstOptions"  />
            </b-form-group>
          </b-col>

          <b-col cols="4">
            <b-form-group>
              <b-button
                variant="primary"
                type="button"
                @click="chamarTodosGraficos()"
              >
                Carregar
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- END ComboBox -->
        <!-- <b-row>
          <b-col>
            <polar-area-chart />
          </b-col>
        </b-row> -->
      
        <b-row>
          <b-col cols="6" style="position: relative;">
            <b-overlay
            :show="showBarChart"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
            
            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
             >    
            <bar-chart 
            ref="barChart"
            :graficoData1="graficoData1" 
            @date-changed="onDateChanged"
            />

          </b-overlay> 
          </b-col>

          <b-col cols="6" style="position: relative;">
            <b-overlay
            :show="showHorizontalBarChart"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
            
            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
             >    
            <horizontal-bar-chart :graficoData2="graficoData2" />
          </b-overlay> 
          </b-col>
        </b-row>
      
      </b-container>
     
    </div>

    <validate-logon-keycloak ref="validatelogon" />
  </div>
</template>

<script>
import axios from 'axios'

import {
  BCard, BRow, BContainer, BCol, BButton, BFormGroup,BOverlay
} from 'bootstrap-vue'
import BarChart from '@/views/home/BarChart.vue'
import PolarAreaChart from '@/views/home/PolarAreaChart.vue'
import HorizontalBarChart from '@/views/home/HorizontalBarChart.vue'
import ComboBoxClient from '@/views/reusable-components/ComboBoxClient.vue'
import ValidateLogonKeycloak from '@/views/reusable-components/ValidateLogonKeycloak.vue'
import chartjsData from "@/views/chartjsData"

export default {
  name: 'Home',
  components: {
    BCard,
    BCol,
    BRow,
    BContainer,
    ValidateLogonKeycloak,
    BarChart,
    PolarAreaChart,
    HorizontalBarChart,
    ComboBoxClient,
    BButton,
    BFormGroup,
    BOverlay
  },
  data() {
    return {
      showBarChart: false, // Overlay do gráfico 1
      showHorizontalBarChart: false, // Overlay do gráfico 2
      graficoData1: {
      labels: [],
      datasets: [
        {
          label: 'Quantidade',
          data: [],
          backgroundColor: '#28dac6',
          borderColor: 'transparent'
        }
      ]
    },
      graficoData2: {
      labels: [],
      datasets: [
        {
          label: 'Quantidade',
          data: [],
          backgroundColor: '#28dac6',
          borderColor: 'transparent'
        }
      ]
    },
    
      rolesNeeded: ['everyone'],
      options: [
        { value: null, text: 'Carregando' },
      ],
      selectClient: {
        id: 'select-client',
      },
    }
  },

  mounted() { // Mounted has to be used when using a Child and trigger after the import
    this.validateUserLogon()
    this.setUser()
    // Define o mês atual como valor inicial no date picker
    const currentDate = new Date();
    const currentMonth = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`;
    this.$refs.barChart.rangePicker = currentMonth
  // Carrega o gráfico com o mês atual
    this.carregarGrafico()
  },

  methods: {
    toast(toaster, append = false, variant, message, title) {
      this.$bvToast.toast(message, {
        title,
        toaster,
        solid: true,
        appendToast: append,
        variant,
      })
    },

    async chamarTodosGraficos(){

      await this.carregarGrafico()
      await this.carregarGrafico2()
    },

    showFirstOptions() {
      // Acessa as options do ComboBoxClient
      const options = this.$refs.cboxclient.options
      console.log("Options do ComboBoxClient:", options);

      // Pega a primeira opção e faz algo com ela, como carregar um gráfico
      if (options && options.length > 0) {
        const firstOption = options[0]
        console.log("Primeira opção:", firstOption);
        // Verifica se a primeira opção tem um valor válido
        if (firstOption.value) {
          // Chama carregarGrafico com o primeiro CNPJ
          
          console.log(firstOption.text)
          this.carregarGrafico(firstOption.value)
          this.$refs.cboxclient.selected = firstOption.value
        }
      }
    },

    async onDateChanged(newDate) {
      //this.showBarChart = true; // Ativa o overlay do BarChart
      try {
        this.$refs.barChart.rangePicker = newDate;
        await this.carregarGrafico();
      } finally {
        //this.showBarChart = false; // Desativa o overlay do BarChart
      }
    },
  
    async carregarGrafico(cnpj) {
      this.showBarChart = true // loading page b-overlay
      try {
        
        const selectedCnpj = cnpj || this.$refs.cboxclient.selected
        
        if (!selectedCnpj ) {
          throw new Error('Selecione um cliente!');
        }
        
        // Usa o mês selecionado ou obtém do date picker
        const selectedMonth = this.$refs.barChart.rangePicker
        
        console.log('month:', selectedMonth)
        // Faz a requisição para a API com o CNPJ selecionado
        const response = await axios.get(`/report/getInfoByMonth/${selectedCnpj}/${selectedMonth}`)
        const rawData = response.data

         console.log('Dados brutos:', rawData)
         console.log('Labels:', rawData.labels);  // Deve ser um array de strings
         console.log('Data:', rawData.data); 
         console.log('month:', selectedMonth); 

        // Verifica se os dados retornados estão vazios ou undefined
        if (!selectedMonth||!rawData || !rawData.labels || rawData.labels.length === 0) {
          this.toast('b-toaster-top-right', true, 'success', `Não há dados`, '0 Processamentos')
          console.warn('Dados ausentes ou estrutura inesperada no rawData')
      
          // Configura o gráfico com labels e datasets vazios para mostrar um gráfico vazio
          this.graficoData1 = {
            labels: [''], // Coloca uma label vazia
            datasets: [
              {
                label: 'Quantidade',
                data: [0],  // Define um valor zero para exibir um gráfico vazio
                backgroundColor: '#e0e0e0',  // Cor neutra para indicar "sem dados"
                borderColor: 'transparent',
              },
            ],
          }
        this.showBarChart = false // Remove o overlay se não houver dados  
        return  // Encerra o método
        
        }

        const formattedLabels = rawData.labels.map((label, index) => `${label} (${rawData.dates[index]})`)

        //console.log('formattedLabels:', formattedLabels)

        this.graficoData1 = {
          labels: formattedLabels,  // ['SAFX2013']
          datasets: [
           {
             label: 'Quantidade',
             data: rawData.data,  // [1708]
             backgroundColor: '#28dac6',  // Cor do fundo
             borderColor: 'transparent',  // Cor da borda
            }
          ]
        }
        
        //console.log( this.graficoData1)
        
             
      } catch (error) {
        console.error('Erro ao carregar os dados do gráfico:', error)
      }
      this.showBarChart = false
    },

    async carregarGrafico2(cnpj) {
      this.showHorizontalBarChart = true // loading page b-overlay
      try {
        const selectedCnpj = cnpj || this.$refs.cboxclient.selected
        if (!selectedCnpj) {
          throw new Error('Selecione um cliente!');
        }

        // Faz a requisição para a API com o CNPJ selecionado
        const response = await axios.get(`/report//getMonthlyRecordsByCNPJ/${selectedCnpj}`)
        const rawData = response.data

         console.log('Dados brutos:', rawData)
         console.log('Labels:', rawData.dates);  // Deve ser um array de strings
         console.log('Data:', rawData.data); 

        // Verifica se os dados retornados estão vazios ou undefined
        if (!rawData || !rawData.dates || rawData.dates.length === 0) {
          console.warn('Dados ausentes ou estrutura inesperada no rawData');
      
          // Configura o gráfico com labels e datasets vazios para mostrar um gráfico vazio
          this.graficoData2 = {
            labels: [''], // Coloca uma label vazia
            datasets: [
              {
                label: 'Quantidade',
                data: [0],  // Define um valor zero para exibir um gráfico vazio
                backgroundColor: '#e0e0e0',  // Cor neutra para indicar "sem dados"
                borderColor: 'transparent',
              },
            ],
          }
        this.showHorizontalBarChart = false // Remove o overlay se não houver dados  
        return  // Encerra o método
        
        }

        //const formattedLabels = rawData.labels.map((label, index) => `${label} (${rawData.dates[index]})`)

        //console.log('formattedLabels:', formattedLabels)

        this.graficoData2 = {
          labels: rawData.dates,  // ["2024-09"]
          datasets: [
           {
             label: 'Quantidade',
             data: rawData.data,  // [1708]
             backgroundColor: '#2ba3cf',  // Cor do fundo
             borderColor: 'transparent',  // Cor da borda
            }
          ]
        }
        
        console.log( this.graficoData2)
        
             
      } catch (error) {
        console.error('Erro ao carregar os dados do gráfico:', error)
      }
      this.showHorizontalBarChart = false
    },
  
  
    validateUserLogon() {
      this.$refs.validatelogon.validateLogon()
    },
    setUser() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      this.$parent.$parent.$parent.$refs.adenav.username = userData.fullName
    },
  },
}
</script>
