var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"novaEmpresaMdl",attrs:{"id":_vm.novaEmpresa.id,"title":_vm.novaEmpresa.title,"content":_vm.novaEmpresa.content,"hide-footer":""}},[_c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v("Dados da Empresa ")]),_c('br'),_c('br'),_c('label',[_vm._v("CNPJ Empresa")]),_c('validation-provider',{attrs:{"rules":"required|digits:14","name":"CNPJ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"CNPJ","readonly":_vm.readonly},model:{value:(_vm.itemCnpj),callback:function ($$v) {_vm.itemCnpj=$$v},expression:"itemCnpj"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Codigo")]),_c('validation-provider',{attrs:{"rules":"required","name":"Cod"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Codigo"},model:{value:(_vm.itemCod),callback:function ($$v) {_vm.itemCod=$$v},expression:"itemCod"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Descrição")]),_c('validation-provider',{attrs:{"rules":"required","name":"Descr"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Descrição"},model:{value:(_vm.itemDescr),callback:function ($$v) {_vm.itemDescr=$$v},expression:"itemDescr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Para")]),_c('validation-provider',{attrs:{"name":"Para"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Para"},model:{value:(_vm.itemPara),callback:function ($$v) {_vm.itemPara=$$v},expression:"itemPara"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"demo-inline-spacing"},[_c('b-button',{attrs:{"id":_vm.registerButton.id,"content":_vm.registerButton.content,"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm()}}},[_vm._v(" "+_vm._s(_vm.registerButton.content)+" ")])],1)],1)],1)],1)],1)]),_c('b-modal',{ref:"modal-edit-empresa",attrs:{"id":"modal-edit-empresa","title":"Editar Empresa","hide-footer":""}},[_c('b-button',{attrs:{"id":"editarEmpresa","variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm('edit')}}},[_vm._v(" Editar Empresa ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }